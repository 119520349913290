import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, message, Modal, Radio, Tooltip} from 'antd';
import InputMask from 'react-input-mask';
import './RegBlock.css'
import {InfoCircleOutlined, RedoOutlined} from "@ant-design/icons";
import {iinCheck} from "../../utils/iin";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {lanGs} from "../../utils/lang/langRegBlock";


const RegBlock = ({lang}) => {
    const [form] = Form.useForm();
    const [perosona, setPersona] = useState('TYPE_PERSONAL');
    const [radioCheck, setRadioCheck] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    /* Отправка данных */
    const onFinish = (values) => {
        values.approval && axios({
            method: "post",
            url: "/sc-initiatives/api/v1/auth/register",
            data: {
                "firstName": values.name,
                "lastName": values.lastName,
                "phone": values.tel.slice(0, 16),
                "email": values.email,
                "bin": values.BIN,
                "password": values.password,
                "company": values.companyName,
                "type": values?.type,
            },
            headers: {"Content-type": "application/json; charset=UTF-8"},
        })
            .then((res) => {
                messageApi.open({
                    type: 'success',
                    content: `${lanGs['regOk'][lang]}`,
                    duration: 5,
                });
                setTimeout(() => navigate('/login'), 3000);
            })
            .catch(function (error) {
                error.response.data.status !== 500 && messageApi.open({
                    type: 'error',
                    content: `${
                        lanGs[error.response.data] && lanGs[error.response.data][lang] ?
                            lanGs[error.response.data][lang] :
                            lanGs['DEF_ERROR'][lang]
                    },`,
                    duration: 5,
                });
            });
    };

    /* Работа с модалкой */
    const onChange = (e) => {
        setRadioCheck(e.target.value === 'TYPE_PERSONAL');
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };  /* Валидация в форме */
    const validateIin = (_, value) => {
        if (!value) {
            return Promise.reject(`${lanGs['Не заполнено'][lang]}`);
        }
        if (!iinCheck(value.toString())) {
            return Promise.reject(`${lanGs['Введите правильный ИИН'][lang]}`);
        }
        return Promise.resolve();
    };

    const validateBin = (_, value) => {
        if (!value) {
            return Promise.reject(`${lanGs['Не заполнено'][lang]}`);
        }
        if (!iinCheck(value.toString())) {
            return Promise.reject(`${lanGs['Введите правильный БИН'][lang]}`);
        }
        return Promise.resolve();
    };

    const validateLastName = (_, value, role) => {
        if (!value && role) {
            return Promise.reject(`${lanGs['Не заполнено'][lang]}`);
        }
        if (!/^[a-zA-Zа-яА-ЯёЁ]+$/.test(value)) {
            return Promise.reject(`${lanGs['Введите только буквы'][lang]}`);
        }
        return Promise.resolve();
    };



    const validatePhoneNumber = (_, value) => {
        if (!value) {
            return Promise.reject(`${lanGs['Не заполнено'][lang]}`);
        }
        if (!/^\+7\(\d{3}\)\d{3} \d{2} \d{2}$/.test(value)) {
            return Promise.reject(`${lanGs['Введите телефонный номер в формате +7(777)777 77 77'][lang]}`);
        }
        return Promise.resolve();
    };

    const resetForm = () => {
        form.resetFields();
        setRadioCheck(true);
        // Дополнительные действия при сбросе формы, если нужно
    };

    return (
        <div className={'RegBlock_wrapper'}>
            <div className={'RegBlock_wrap'}>
                <Form
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{maxWidth: 600}}
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                    initialValues={{
                        type: perosona
                    }}
                >
                    {contextHolder}
                    <Form.Item noStyle>
                        <div className={'RegBlock_title_wrap'}>
                            <span className={'RegBlock_title_text'}>{lanGs['Регистрация пользователя'][lang]}</span>
                            <Button htmlType="reset" onClick={resetForm}><RedoOutlined/></Button>
                        </div>
                    </Form.Item>
                    <Form.Item noStyle name='type'>
                        <Radio.Group onChange={onChange} className={'RegBlock_radio'} name={'type'}>
                            <Radio value={'TYPE_PERSONAL'}>{lanGs['Физическое лицо'][lang]}</Radio>
                            <Radio value={'TYPE_COMPANY'}>{lanGs['Юридическое лицо'][lang]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {radioCheck && <Form.Item
                        label={lanGs["Фамилия"][lang]}
                        name="lastName"
                        rules={[{validator: validateLastName,},]}
                        className={'RegBlock_line'}
                    >
                        <Input  size="large" className={'Password_type_input'}/>
                    </Form.Item>}
                    {radioCheck && <Form.Item
                        label={lanGs["Имя"][lang]}
                        name="name"
                        rules={[{validator: validateLastName,},]}
                        className={'RegBlock_line'}
                    >
                        <Input  size="large" className={'Password_type_input'}/>
                    </Form.Item>}
                    {radioCheck && <Form.Item
                        label={lanGs["ИИН"][lang]}
                        name="BIN"
                        rules={[{validator: validateIin,},]}
                        className={'RegBlock_line'}
                    >
                        <Input  size="large" className={'Password_type_input'} maxLength={12}/>
                    </Form.Item>}
                    {!radioCheck && <Form.Item
                        label={lanGs["БИН"][lang]}
                        name="BIN"
                        rules={[{validator: validateBin,},]}
                        className={'RegBlock_line'}
                    >
                        <Input size="large" className={'Password_type_input'} maxLength={12}/>
                    </Form.Item>}
                    <Form.Item
                        label={lanGs["Контакты"][lang]}
                        name={"tel"}
                        rules={[{validator: validatePhoneNumber,},]}
                        className={'RegBlock_line'}

                    >
                        <InputMask mask="+7(999)999 99 99" maskChar={null}>
                            {(inputProps) => <Input {...inputProps}  size="large" className={'Password_type_input'} maxLength={16}/>}
                        </InputMask>
                    </Form.Item>
                    <Form.Item
                        label={lanGs["Электронная почта"][lang]}
                        name={'email'}
                        rules={[{required: true, type: 'email', message: `${lanGs['Не заполнено'][lang]}`,},]}
                        className={'RegBlock_line'}
                    >
                        <Input className={'Email_type_input'}  size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Tooltip
                                placement="topRight"
                                color={"#146eb0"}
                                overlayClassName={"Tooltip_wrapper"}
                                title={
                                    <div className={"tooltip_text_form_notif_wrap"}>
                                        <span className={'tooltip_text_form_notif'}>{lanGs['Требования к паролю:'][lang]}</span>
                                        <ul>
                                            <li>{lanGs['не менее 6 символов'][lang]}</li>
                                            <li>{lanGs['содержать цифры'][lang]}</li>
                                            <li>{lanGs['содержать спецсимвол'][lang]}</li>
                                            <li>{lanGs['буквы в нижнем регистре'][lang]}</li>
                                            <li>{lanGs['буквы в верхнем регистре'][lang]}</li>
                                        </ul>
                                    </div>}
                            >
                                Пароль <InfoCircleOutlined/>
                            </Tooltip>
                        }
                        name="password"
                        rules={[
                            {required: true, message: `${lanGs['Не заполнено'][lang]}`},
                            {
                                pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g,
                                message: `${lanGs['не соответствует требованиям'][lang]}`,
                            },
                        ]}
                        className={'RegBlock_line pass_input'}
                    >
                        <Input.Password  size="large" className={'Password_type_input'}/>
                    </Form.Item>
                    <Form.Item noStyle>
                        <Form.Item className={'Item_forget_wrap'}>
                            <a href="#" className={'Item_assent'} onClick={() => setIsModalOpen(true)}>{lanGs['Согласие о предоставлении персональных данных'][lang]}</a>
                        </Form.Item>
                        <Form.Item
                            name="approval"
                            valuePropName="checked"
                            rules={[{required: true, message: `${lanGs['Не выбрано'][lang]}`,},]}
                            className={'RegBlock_line_check Checkbox_item'}
                        >
                            <Checkbox className={'check_form_type'}>{lanGs['Ознакомился (-ась) и согласен (-а)'][lang]}</Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item className={'btn_submit_item_wrap'}>
                        <Button className={'btn_submit_item'} onClick={() => navigate('/login')}>{lanGs['Отменить'][lang]}</Button>
                        <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Зарегистрировать'][lang]}</Button>
                    </Form.Item>
                </Form>
                <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} header={false} footer={false} centered width={'70vw'}>
                    <div className={'personal_data_text'}>
                        <span>{lanGs['СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ'][lang]}</span>
                        <span>{lanGs['textReg'][lang]}</span>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default RegBlock;
