export const lanGs = {
    'Сервис предложения инициатив': {
        nameRu: "Сервис предложения инициатив",
        nameKz: "Бастамаларды ұсыну сервисі",
    },
    'лого сервиса предложения инициатив': {
        nameRu: "лого сервиса предложения инициатив",
        nameKz: "бастамаларды ұсыну сервисінің логосы",
    },
    "Ситуационный": {
        nameRu: "Ситуационный",
        nameKz: "Ахуалдық",
    },
    "центр": {
        nameRu: "центр",
        nameKz: "орталық",
    },
    "Сервис предложения": {
        nameRu: "Сервис предложения",
        nameKz: "Бастамаларды ",
    },
    "инициатив": {
        nameRu: "инициатив",
        nameKz: "ұсыну сервисі",
    },
    "Центр развития": {
        nameRu: "Центр развития",
        nameKz: "Алматы қаласын",
    },
    "города алматы": {
        nameRu: "города алматы",
        nameKz: "дамыту орталығы",
    },
    "Оставить отзыв": {
        nameRu: "Оставить отзыв",
        nameKz: "Пікір қалдыру",
    },
    "Центр развития города Алматы": {
        nameRu: "Центр развития города Алматы",
        nameKz: "Алматы қаласын дамыту орталығы",
    },
    "Акимат города Алматы": {
        nameRu: "Акимат  города Алматы",
        nameKz: "Алматы қаласының әкімдігі",
    },
    "Управление Цифровизации города Алматы": {
        nameRu: "Управление   Цифровизации города Алматы",
        nameKz: "Алматы қаласы Цифрландыру басқармасы",
    },
    'Вход': {
        nameRu: "Вход",
        nameKz: "Кіру",
    },
    "Главная страница": {
        nameRu: "Главная страница",
        nameKz: "Басты бет",
    },
    "Ситуационный центр": {
        nameRu: "Ситуационный центр",
        nameKz: "Ахуалдық орталық",
    },
    'Лого ситуационного центра города Алматы': {
        nameRu: "Лого ситуационного центра города Алматы",
        nameKz: "Алматы қаласының ахуалдық орталығының логосы",
    },
    'Ситуационный центр города Алматы': {
        nameRu: "Ситуационный центр города Алматы",
        nameKz: "Алматы қаласының Ахуалдық орталығы",
    },
    'Выход': {
        nameRu: "Выход",
        nameKz: "Шығу",
    },
    'Обратная связь': {
        nameRu: "Обратная связь",
        nameKz: "Кері байланыс",
    },
    'Профиль': {
        nameRu: "Профиль",
        nameKz: "Профиль",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
