import React, { Component } from 'react';
import {useNavigate} from 'react-router-dom'
import Cookies from "js-cookie";


import {useLocation } from 'react-router-dom';
import axios from "axios";
import {message} from "antd";


const AuthSocial = ({lang, setUserI}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();
    const navigate = useNavigate();
    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    const token = getUrlParameter('token');

    if(token) {


            Cookies.set('jwt', token)
     axios({
            method: "get",
            url: `/sc-initiatives/api/v1/user/profile`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {


                Cookies.set('name', res.data.name);
                Cookies.set('email', res.data.email);
                Cookies.set('roles', res.data.roles);
                setUserI(res.data);
                navigate('/')
            })
         .catch(function (error) {
             if(error.request.statusText === 'Unauthorized' ){
                 messageApi.open({
                     type: 'error',
                     content: `${langS['Unauthorized'][lang]}`,
                     duration: 5,
                 }, );
                 setTimeout(function() {

                     Cookies.remove('jwt')
                     navigate('/login')
                 }, 1000)
             }

         });


    } else {
        navigate('/login') ;
    }

};

export default AuthSocial;
let langS = {
    'Unauthorized': {
        nameRu: "Требуется повторная авторизация. Вас перенаправит на страницу авторизации",
        nameKz: "Қайта авторизациялау қажет. Сізді авторизация бетіне бағыттайды",
    },
}
