import React from 'react';
import './RulesBlock.css'
import BackBlock from "../common/element/BackBlock";
import {lanGs} from '../utils/lang/langRulesBlock'
const RulesBlock = ({lang, setCatCardClick, setData, setDistChoice}) => {

    return (
        <div className={'RulesBlock_wrapper'}>
            <div className={'RulesBlock_wrap'}>
                <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
                <div className={'RulesBlock'}>
                    <h2>{lanGs['Правила при подаче инициативы'][lang]}</h2>
                    <span >{lanGs['Для того, чтобы Пользователь мог подавать собственные инициативы, он должен:'][lang]} </span>
                    <p>{lanGs['1n1'][lang]}</p>
                    <p>{lanGs['1n2'][lang]}</p>
                    <p>{lanGs['1n3'][lang]}</p>
                    <p>{lanGs['1n4'][lang]}</p>

                    <h2>{lanGs['Инициатива не будет допущена к голосованию, если:'][lang]}</h2>

                    <p>{lanGs['2n1'][lang]}</p>
                    <p>{lanGs['2n2'][lang]}</p>
                    <p>{lanGs['2n3'][lang]}</p>
                    <p>{lanGs['2n4'][lang]}</p>
                    <p>{lanGs['2n5'][lang]}</p>
                    <p>{lanGs['2n6'][lang]}</p>
                    <p>{lanGs['2n7'][lang]}</p>
                    <p>{lanGs['2n8'][lang]}</p>
                    <p>{lanGs['2n9'][lang]}</p>
                    <p>{lanGs['2n10'][lang]}</p>
                    <p>{lanGs['2n11'][lang]} </p>
                    <p>{lanGs['2n12'][lang]}</p>
                    <p>{lanGs['2n13'][lang]}</p>
                    <h2>{lanGs['Правила проведения голосования:'][lang]}</h2>
                    <p>{lanGs['3n1'][lang]}</p>
                </div>
            </div>
        </div>
    );
};

export default RulesBlock;
