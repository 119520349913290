import React, {useState} from 'react';
import './WorkMapPage.css'
import BackBlock from "../common/element/BackBlock";
import GisSMR from "../common/element/WMap/GisSMR";

const WorkMapPage = ({lang, setCatCardClick, setData, setLang}) => {
    return (
        <div className={`WorkMapPage_wrap`}>
            <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData}  page={false}/>
            <GisSMR lang={lang} setLang={setLang}/>


        </div>
    );
};

export default WorkMapPage;
