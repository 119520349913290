import React, {useEffect, useState} from 'react';
import './IniMapPage.css'
import BackBlock from "../common/element/BackBlock";

import DistrictNav from "../common/element/iniMap/DistrictNav";
import Main from "../common/element/iniMap/Main";

const IniMapPage = ({lang, setCatCardClick, dataCat, data, setClickInitiative, setData, distChoice, setDistChoice, setMapCatClick, tabBtn, dataCardPas}) => {
    const [toMark, setToMark] = useState(null);
    const [cardSelect, setCardSelect] = useState(null);
    useEffect(() => {
        dataCardPas?.geom?.coordinates && setToMark(dataCardPas.geom.coordinates)
        dataCardPas && setCardSelect(dataCardPas)
    }, [dataCardPas]);
    return (
            <div className={`IniMapPage_wrap`}>
                <BackBlock lang={lang} link={'/'} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
                <div className={'DistrictNav_block'}>
                    <DistrictNav
                        lang={lang}
                        distChoice={distChoice}
                        setDistChoice={setDistChoice}
                        setToMark={setToMark}
                    />
                    <Main
                        distChoice={distChoice}
                        setDistChoice={setDistChoice}
                        lang={lang}
                        toMark={toMark}
                        setToMark={setToMark}
                        dataCat={dataCat}
                        data={data}
                        setCatCardClick={setCatCardClick}
                        setClickInitiative={setClickInitiative}
                        setMapCatClick={setMapCatClick}
                        tabBtn={tabBtn}
                        cardSelect={cardSelect}
                        setCardSelect={setCardSelect}
                    />
                </div>
            </div>

    );
};

export default IniMapPage;
