import React from 'react';
import {useNavigate} from "react-router-dom";
import './BackBlock.css'
import Cookies from "js-cookie";
import {lanGs} from '../../utils/lang/langBackBlock'
const BackBlock = ({lang, setCatCardClick, setData, page=true}) => {
    const navigate = useNavigate();
    window.addEventListener('popstate', function(event) {
        Cookies.remove('catC');
    });
    return (
        <div className={'BackBlock_wrap'}>
            <div className={'back_btn_item'} onClick={() => {
                setCatCardClick(null)
                navigate(-1)
                page && setData(null)

                Cookies.remove('catC');
            }}>
                <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.375 0.166338C12.625 0.166338 13.4583 0.999672 13.4583 2.24967C13.4583 2.87467 13.25 3.29134 12.8333 3.70801L5.54168 10.9997L12.8333 18.2913C13.6667 19.1247 13.6667 20.3747 12.8333 21.208C12 22.0413 10.75 22.0413 9.91668 21.208L1.16668 12.458C0.333343 11.6247 0.333343 10.3747 1.16668 9.54134L9.91668 0.79134C10.3333 0.374674 10.9583 0.166338 11.375 0.166338Z"
                        fill="#1F4DC5"/>
                </svg>
                <span>{lanGs['Назад'][lang]}</span>
            </div>
        </div>
    );
};

export default BackBlock;
