import React, {useState} from 'react';
import './NotPage.css'

import BackBlock from "../common/element/BackBlock";

const NotPage = ({lang, setCatCardClick, setData, setDistChoice}) => {

    return (
        <div className={`NotPage_wrapper`}>
            <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
            <div className={`NotPage_wrap`}>

            </div>
        </div>
    );
};

export default NotPage;
