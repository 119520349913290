import React from "react";
import {Marker, Polygon, Polyline, Popup} from "react-leaflet";
import L, {MarkerCluster} from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import PopupItem from "../Elements/popupItem";
import "leaflet/dist/leaflet.css";
import "../Style/ClusterMapLayer.css";

import DEF from "../../../../assets/def.svg";



const ClusterMapLayer = ({data ,lang, setCardSelect, setToMark, setClickInitiative}) => {


    /*вид иконки группировки значков */

    const createClusterCustomIcon = function (cluster: MarkerCluster) {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: "clusterMarker_wrap",
            iconSize: L.point(25, 25, true)
        });
    };
    let poligonAdd = (item, icon) => {
        switch (item?.geom?.type) {

            case"MultiLineString":
                return <Polyline
                    pathOptions={{weight: 3, dashArray: 8}}
                    positions={item?.geom?.coordinates}
                    eventHandlers={{
                        mouseover: (event) => event.target.openPopup(),
                        click: (e) => {
                            setToMark(null);
                            setCardSelect(null);
                            setCardSelect(item);
                            setClickInitiative(item.id)
                        },
                    }}
                >
                    <Popup key={item.id + "props marker"}>
                        <PopupItem data={item} lang={lang}/>
                    </Popup>
                </Polyline>

            case "MultiPolygon":
                return <Polygon
                    pathOptions={{fillOpacity: .9, weight: 2, opacity: .9, }}
                    positions={item?.geom?.coordinates}
                    eventHandlers={{
                        mouseover: (event) => event.target.openPopup(),
                        click: (e) => {
                            setToMark(null);
                            setCardSelect(null);
                            setCardSelect(item);
                            setClickInitiative(item.id)
                        },
                    }}
                >
                    <Popup key={item.id + "props marker"}>
                        <PopupItem data={item} lang={lang}/>
                    </Popup>
                </Polygon>
            case 'Point':
                return <Marker
                    key={item.id + "mark_item"}
                    position={[item.geom.coordinates[1], item.geom.coordinates[0]]}
                    icon={icon}
                    eventHandlers={{

                        mouseover: (event) => event.target.openPopup(),
                        click: (e) => {
                            setToMark(null);
                            setCardSelect(null);
                            setCardSelect(item);
                            setClickInitiative(item.id)
                        },
                    }}
                >
                    <Popup key={item.id + "props marker"}>
                        <PopupItem data={item} lang={lang}/>
                    </Popup>
                </Marker>
            default: break
        }
    }

    const clusterLayer = data && data.map((item, index) => {

        let iconD = new L.divIcon({
            html: `<div class="Round_marker_icon" style="outline-width: 2px ; outline-style: solid; outline-color: ${item.status ? item.status.color : '#005797'}">
                <span style="background-image: url('${item?.categoryInitiative?.iconMap ? item.categoryInitiative.iconMap : DEF}');" /></div>`,// дефолтная иконка
            className: ``,
            iconSize: [25, 25],// размер иконки
            iconAnchor: [15, 15]
        })
//         let iconD2 = new L.divIcon({
//             // eslint-disable-next-line no-useless-concat
//             html: `<div class="Round_marker_icon" style="outline-width: 2px ; outline-style: solid; outline-color: ${item.status ? item.status.color : '#005797'}">
//
// </div>`,// дефолтная иконка
//             className: ``,
//             iconSize: [15, 15],// размер иконки
//             iconAnchor: [10, 10]
//         })

        return poligonAdd(item, iconD)

    });
    return (
        <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}//
            removeOutsideVisibleBounds={true}
            zoomToBoundsOnClick={true}
            spiderfyOnMaxZoom={true}
            animate={true}
            disableClusteringAtZoom={17}
            /*  spiderfyOnMaxZoom={true}*/
            showCoverageOnHover={false}// показывать зону захвата меток
            /* polygonOptions={clusterOpt && clusterOpt}*/
        >
            {clusterLayer}
        </MarkerClusterGroup>
    );
};

export default ClusterMapLayer;




