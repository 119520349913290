export const lanGs = {
    'Перенесено в архив': {
        nameRu: 'Перенесено в архив',
        nameKz: 'Мұрағатқа көшірілді',
    },
    'Что то пошло не так, попробуйте позднее': {
        nameRu: "Что то пошло не так, попробуйте позднее",
        nameKz: "Бірдеңе дұрыс болмады, кейінірек көріңіз",
    },
    'Редактировать': {
        nameRu: "Редактировать",
        nameKz: "Өңдеу",
    },
    'В архив': {
        nameRu: "В архив",
        nameKz: "Мұрағатқа",
    },
    'Профиль': {
        nameRu: "Профиль",
        nameKz: "Профиль",
    },
    'Редактировать профиль': {
        nameRu: "Редактировать профиль",
        nameKz: "Профильді өңдеу",
    },
    'Рейтинг пользователя': {
        nameRu: "Рейтинг пользователя",
        nameKz: "Пайдаланушы рейтингі",
    },
    'Компания': {
        nameRu: "Компания",
        nameKz: "Компания",
    },
    'Имя пользователя': {
        nameRu: "Имя пользователя",
        nameKz: "Пайдаланушы аты",
    },
    'ОКЭД': {
        nameRu: "ОКЭД",
        nameKz: "ЭҚЖЖ",
    },
    'Не найден': {
        nameRu: "Не найден",
        nameKz: "Табылған жоқ",
    },
    'ФИО руководителя': {
        nameRu: "ФИО руководителя",
        nameKz: "Басшының ТАӘ",
    },
    "не указан": {
        nameRu: "не указан",
        nameKz: "көрсетілмеген",
    },
    'Пол': {
        nameRu: "Пол",
        nameKz: "Жындысы",
    },
    'ИИН / БИН': {
        nameRu: "ИИН / БИН",
        nameKz: "ЖСН / БСН",
    },
    'Контактный номер': {
        nameRu: "Контактный номер",
        nameKz: "Байланыс нөмірі",
    },
    'Адрес': {
        nameRu: "Адрес",
        nameKz: "Мекен-жайы",
    },
    'Социальные сети': {
        nameRu: "Социальные сети",
        nameKz: "Әлеуметтік медиа",
    },
    'Задать вопрос': {
        nameRu: "Задать вопрос",
        nameKz: "Сұрақ қою",
    },
    'Новое объявление': {
        nameRu: "Новое объявление",
        nameKz: "Жаңа хабарландыру",
    },
    'Объявления': {
        nameRu: "Объявления",
        nameKz: "Хабарландырулары",
    },
    'Активные объявления': {
        nameRu: "Активные объявления",
        nameKz: "Белсенді хабарландырулар",
    },
    'На проверке': {
        nameRu: "На проверке",
        nameKz: "Тексеруде",
    },
    'Архив': {
        nameRu: "Архив",
        nameKz: "Мұрағат",
    },
    'Избранное': {
        nameRu: "Избранное",
        nameKz: "Таңдаулылар",
    },
    'Активные': {
        nameRu: "Активные",
        nameKz: "Белсенді",
    },
    'Подтвержденный резерв': {
        nameRu: "Подтвержденный резерв",
        nameKz: "Расталған резерв",
    },
    'Не подтвержденный резерв': {
        nameRu: "Не подтвержденный резерв",
        nameKz: "Расталмаған резерв",
    },
    'Акции': {
        nameRu: "Акции",
        nameKz: "Акциялар",
    }
    ,
    'Убрано из избранного': {
    nameRu: "Убрано из избранного",
        nameKz: "Таңдаулылардан алынып тасталды",
},
    'Убрать из избранного': {
        nameRu: "Убрать из избранного",
        nameKz: "Таңдаулылардан алып тастаңыз",
    },
    'Основной код экономической деятельности': {
        nameRu: "Основной код экономической деятельности",
        nameKz: "Экономикалық қызметтің негізгі коды",
    },
    'Мужской': {
        nameRu: "Мужской",
        nameKz: "Еркек",
    },
    'Женский': {
        nameRu: "Женский",
        nameKz: "Әйел",
    },
    'Номер инициативы': {
        nameRu: "Номер инициативы",
        nameKz: "Бастама нөмірі",
    },
    'Принятые': {
        nameRu: "Принятые",
        nameKz: "Қабылданды",
    },
    'Дата регистрации': {
        nameRu: "Дата регистрации",
        nameKz: "Тіркелген күні",
    },
    'Отказ': {
        nameRu: "Отказ",
        nameKz: "Бас тарту",
    },
    'На доработку': {
        nameRu: "На доработку",
        nameKz: "Пысықтау үшін",
    },
    'Новая инициатива': {
        nameRu: "Новая инициатива",
        nameKz: "Жаңа бастама",
    },
    'Активные инициативы': {
        nameRu: "Активные инициативы",
        nameKz: "Белсенді бастамалар",
    },
    'Инициативы': {
        nameRu: "Инициативы",
        nameKz: "Бастамалар",
    },
}
/*
'': {
    nameRu: "",
        nameKz: "",
},
*/

