import React from 'react';
import './AboutProject.css'
import BackBlock from "../common/element/BackBlock";

const AboutProject = ({lang, setCatCardClick, setData, setDistChoice}) => {

    return (
        <div className={`AboutProject_wrapper`}>
            <div className={`AboutProject_wrap`}>
                <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
                <div className={'AboutProject'}>
                    <h2>{lanGs['Добро пожаловать на наш сайт'][lang]}</h2>
                    <p>{lanGs['Здесь каждый житель'][lang]}</p>
                    <p> {lanGs['Мы стремимся'][lang]} </p>
                    <p>{lanGs['Здесь каждый ваш голос'][lang]}</p>
                    <p>{lanGs['Мы верим в силу'][lang]}</p>
                    <p> {lanGs['Наш проект - это'][lang]}</p>
                    <p> {lanGs['Мы предоставляем'][lang]}</p>
                    <p>{lanGs['Присоединяйтесь'][lang]}</p>
                </div>


            </div>
        </div>
    );
};

export default AboutProject;
 const lanGs = {
    'Добро пожаловать на наш сайт': {
        nameRu: 'Добро пожаловать на наш сайт, посвященный улучшению жизни в прекрасном городе Алматы!',
        nameKz: 'Әдемі Алматы қаласында өмірді жақсартуға арналған Біздің сайтқа қош келдіңіз!',
    },
     'Здесь каждый житель': {
         nameRu: 'Здесь каждый житель города может делиться своими идеями и предложениями, направленными на развитие социально-экономической сферы,' +
             ' улучшение местного управления и другие аспекты городской жизни.',
         nameKz: 'Мұнда қаланың әрбір тұрғыны әлеуметтік-экономикалық саланы дамытуға бағытталған өз идеялары мен ұсыныстарымен бөлісе алады,' +
             'жергілікті басқаруды жақсарту және қала өмірінің басқа аспектілері.',
     },
     'Мы стремимся': {
         nameRu: 'Мы стремимся активно включить горожан в формирование будущего Алматы. Наша цель - создать особый ресурс, который объединит лучшие идеи ' +
             'в едином “Банке идей“.',
         nameKz: 'Біз қала тұрғындарын Алматының болашағын қалыптастыруға белсенді түрде қосуға тырысамыз. Біздің мақсатымыз-ең жақсы идеяларды біріктіретін' +
             ' арнайы ресурс құру бірыңғай" Идеялар банкінде".',
     },
     'Здесь каждый ваш голос': {
         nameRu: 'Здесь каждый ваш голос имеет значение, и ваше предложение может оказать значительное влияние на развитие нашего города',
         nameKz: 'Мұнда сіздің әрбір дауысыңыз маңызды және сіздің ұсынысыңыз қаламыздың дамуына айтарлықтай әсер етуі мүмкін',
     },
     'Мы верим в силу': {
         nameRu: 'Мы верим в силу сотрудничества и уверены, что только сообща мы сможем сделать Алматы еще лучше. Присоединяйтесь к нашему сообществу,' +
             ' делитесь своими идеями и поддерживайте предложения других жителей. Давайте превратим наш город в место, которым каждый будет гордиться!',
         nameKz: 'Біз ынтымақтастықтың күшіне сенеміз және Алматыны бірігіп қана жақсарта алатынымызға сенімдіміз. Біздің қоғамдастыққа қосылыңыз, ' +
             'өз идеяларыңызбен бөлісіңіз және басқа тұрғындардың ұсыныстарын қолдаңыз. Біздің қаланы әркім мақтанатын орынға айналдырайық!',
     },
     'Наш проект - это': {
         nameRu: 'Наш проект - это не только возможность выразить свое видение, но также площадка для активного общения и обмена мнениями с другими жителями' +
             ' Алматы.',
         nameKz: 'Біздің жоба-бұл өз көзқарасымызды білдіру мүмкіндігі ғана емес, сонымен қатар Алматының басқа тұрғындарымен белсенді қарым-қатынас' +
             ' пен пікір алмасу алаңы.',
     },
     'Мы предоставляем': {
         nameRu: 'Мы предоставляем вам инструменты для демократической инициативы и активного участия. Ваше участие делает наше сообщество сильнее и' +
             ' помогает нам двигаться вперед к созданию лучшего будущего для Алматы.',
         nameKz: 'Біз сізге демократиялық бастама мен белсенді қатысудың құралдарын ұсынамыз. Сіздің қатысуыңыз біздің қоғамдастықты күшейтеді және Алматы ' +
             'үшін жақсы болашақ құруға алға жылжуға көмектеседі.',
     },
     'Присоединяйтесь': {
         nameRu: 'Присоединяйтесь к нашему движению и вместе строим будущее нашего замечательного города. Ваш голос, ваши идеи и ваше участие имеют' +
             ' значение. Давайте вдохновлять друг друга и создавать более благоприятное будущее для Алматы!',
         nameKz: 'Біздің қозғалысқа қосылыңыз және керемет қаламыздың болашағын бірге құрыңыз. Сіздің дауысыңыз, идеяларыңыз және қатысуыңыз маңызды. ' +
             'Бір-бірімізді шабыттандырып, Алматы үшін анағұрлым қолайлы болашақ құрайық!',
     },
}
