export const lanGs = {
    'footerText': {
        nameRu: "Ситуационный центр Алматы. Все права защищены",
        nameKz: "Алматы Ахуалдық орталығы. Барлық құқықтар қорғалған",
    },

}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
