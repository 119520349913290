import React, { useEffect, useState } from "react";
import "../Style/Map.css";
import "leaflet/dist/leaflet.css";
import { GeoJSON, MapContainer, TileLayer, useMap } from "react-leaflet";


import "antd/dist/reset.css";

import LeafletControlGeocoder from "../Elements/searchItem";

import ClusterMapLayer from "./ClusterMapLayer";
import { distCenterSet } from "../../../../utils/helper";
import LegendItem from "../Elements/legendItem";
import L from "leaflet";
const Map = ({
  mapType,
  data,
  toMark,
  setToMark,
  distShape,
  distChoice,
  lang,
  setCardSelect,
  cardSelect,
 setClickInitiative,
 filterStatus,
}) => {

  const [distBorder, setDistBorder] = useState();
  const [cityBorder, setCityBorder] = useState();

    let SetViewOnClick = () => {
        const map = useMap();
        useEffect(() => {
            map.eachLayer(function (layer) {layer.closePopup();});
            let ttr = distCenterSet(distChoice.id.toString());
            toMark? map.flyTo([toMark[0], toMark[1]], 16):  map.flyTo(ttr.center, ttr.zoom);
            map.closePopup();
            toMark && map.openPopup(`${cardSelect?.name}`, [toMark[0], toMark[1]]);
        }, [toMark, distChoice, cardSelect]);
    };


  useEffect(() => {
    if (distShape) {
      setCityBorder(
          distShape.map((i) => i.id === 10 && (<GeoJSON data={{ type: i.type, coordinates: JSON.parse(i.coordinates) }} key={"AlmatyKey" + i.id}/>)));
      setDistBorder(distShape.map((i) => distChoice.id !== 0 && !i.nameRu.includes(distChoice.dist) &&
          (<GeoJSON data={{ type: i.type, coordinates: JSON.parse(i.coordinates) }} key={"DistKey" + i.id}/>)));
    }
  }, [distShape]);

  return (
    <div className={"Map_wrapper"}>
      <MapContainer
        style={{ width: "100%", height: "100%" }}
        className="markercluster-map"
        id="mapMain"
        center={ [43.21123, 76.949821]}
        zoom={12}
        zoomControl={true}
        scrollWheelZoom={true}
        fadeAnimation={true}
        markerZoomAnimation={true}
        fullscreenControl={true}
        crs={L.CRS.EPSG3395} // Используем проекцию EPSG:3395
      >
        <TileLayer url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&lang=ru_RU&ads=enabled"/>
        <SetViewOnClick coords={toMark} />
        <LeafletControlGeocoder data={data} />
        {distBorder && mapType === "cluster" && distBorder}
        {cityBorder && mapType === "cluster" && cityBorder}

          <ClusterMapLayer
            data={filterStatus ? data?.filter(i => i.status.id === filterStatus): data}
            lang={lang}
            setCardSelect={setCardSelect}
            setToMark={setToMark}
            setClickInitiative={setClickInitiative}
          />
      </MapContainer>
      {data && mapType === "cluster" && (<LegendItem data={data} lang={lang} setCardSelect={setCardSelect} />)}
    </div>
  );
};

export default Map;
