import React from "react";

export const lanGs = {
    'SEND_Charity': {
        nameRu: "Инициатива принята на обработку",
        nameKz: "Бастама өңдеуге қабылданды",
    },
    'DEF_ERROR': {
        nameRu: "Что то пошло не так :(",
        nameKz: "Бірдеңе дұрыс болмады :(",
    },
    'Подтверждение изменений': {
        nameRu: "Подтверждение изменений",
        nameKz: "Өзгерістерді растау",
    },
    'Введите телефонный': {
        nameRu: "Введите телефонный номер в формате ",
        nameKz: "Телефон нөмірін форматта енгізіңіз",
    },
    'Пожалуйста, используйте только кириллицу для ввода.': {
        nameRu: "Пожалуйста, используйте только кириллицу для ввода.",
        nameKz: "Теру үшін тек кириллицаны пайдаланыңыз.",
    },
    'Новое объявление': {
        nameRu: "Новое объявление",
        nameKz: "Жаңа хабарландыру",
    },
    'Выбрать раздел / категорию / подкатегорию': {
        nameRu: "Выбрать раздел / категорию / подкатегорию",
        nameKz: "Бөлімді / санатты / ішкі санатты таңдаңыз",
    },
    'Подача инициативы': {
        nameRu: "Подача инициативы",
        nameKz: "Бастама беру",
    },
    'Категория инициативы': {
        nameRu: "Категория инициативы",
        nameKz: "Бастама санаты",
    },
    'Не выбрано': {
        nameRu: "Не выбрано",
        nameKz: "Таңдалмаған",
    },
    'Категория работ': {
        nameRu: "Категория работ",
        nameKz: "Жұмыс санаты",
    },
    'Категория объектов': {
        nameRu: "Категория объектов",
        nameKz: "Объектілер санаты",
    },
    'Район': {
        nameRu: "Район",
        nameKz: "Аудан",
    },
    'Улица': {
        nameRu: "Улица",
        nameKz: "Көше",
    },
    '№ дома': {
        nameRu: "№ дома",
        nameKz: "Үйдің №",
    },
    'Дом': {
        nameRu: "Дом",
        nameKz: "Үй",
    },
    'Краткое название инициативы': {
        nameRu: "Краткое название инициативы",
        nameKz: "Бастаманың қысқаша атауы",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Максимальное количество символов:': {
        nameRu: "Максимальное количество символов:",
        nameKz: "Таңбалардың максималды саны:",
    },
    'Лимит символов:':{
        nameRu: "Лимит символов:",
        nameKz: "Таңбалар шегі:",
    },
    'Подробное описание инициативы': {
        nameRu: "Подробное описание инициативы",
        nameKz: "Бастаманың толық сипаттамасы",
    },
    'Предлагаемые пути решения': {
        nameRu: "Предлагаемые пути решения",
        nameKz: "Ұсынылған шешімдер",
    },
    'Источник финансирования': {
        nameRu: "Источник финансирования",
        nameKz: "Қаржыландыру көзі",
    },
    'Добавить вложения': {
        nameRu: "Добавить вложения",
        nameKz: "Тіркемелер қосу",
    },
    'Выбрать вложение': {
        nameRu: "Выбрать вложение",
        nameKz: "Тіркемені таңдаңыз",
    },
    'Отправить': {
        nameRu: "Отправить",
        nameKz: "Жіберу",
    },
    'Подтверждение подачи инициативы': {
        nameRu: "Подтверждение подачи инициативы",
        nameKz: "Бастаманы растау",
    },
    'Вы действительно хотите отправить заявку на рассмотрение?': {
        nameRu: "Вы действительно хотите отправить заявку на рассмотрение?",
        nameKz: "Сіз шынымен қарауға өтініш жібергіңіз келе ме?",
    },
    'Объем ф': {
        nameRu: "Объем файлов не более 20 МБ (.pdf, .xls, .doc .jpg, .jpeg, .png)",
        nameKz: "Файл көлемі 20 МБ-тан аспайды (.pdf, .xls, .doc .jpg, .jpeg, .png)",
    },
    'Редактирование инициативы': {
        nameRu: "Редактирование инициативы",
        nameKz: "Бастаманы редакциялау",
    },
    'Unauthorized': {
        nameRu: "Требуется повторная авторизация. Вас перенаправит на страницу авторизации",
        nameKz: "Қайта авторизациялау қажет. Сізді авторизация бетіне бағыттайды",
    },
    'onModer': {
        nameRu: "Инициатива на проверке модератора",
        nameKz: "Модераторды тексеру бастамасы",
    },
    'Поиск категории объекта': {
        nameRu: "Поиск категории объекта",
        nameKz: "Нысан санатын іздеу",
    },
    'Опубликовать': {
        nameRu: "Опубликовать",
        nameKz: "Жариялау",
    },
    'Хочу помочь': {
        nameRu: "Хочу помочь",
        nameKz: "Көмектескім келеді",
    },
    'Нуждаюсь в помощи': {
        nameRu: "Нуждаюсь в помощи",
        nameKz: "Көмекке мұқтаж",
    },'Вы действительно хотите внести изменения в профиль?': {
        nameRu: "Вы действительно хотите внести изменения в профиль?",
        nameKz: "Сіз шынымен профильге өзгертулер енгізгіңіз келеді?",
    },
    'contentCatIni':{
        nameRu: <div className={'Popover_text_wrap'}>
            <span>Если в данной форме вы не нашли категорию или улицу которая соответствует вашему запросу, вы можете <a href="https://docs.google.com/forms/d/e/1FAIpQLSeibReftR_6KsUPkT1zpWTb7M-H_pdrAuoxbj8r9qRBvy1GKw/viewform?usp=sf_link" target={'_blank'}>оставить заявку</a> для добавления категории/улицы в форму.</span>

        </div>,
        nameKz: <div className={'Popover_text_wrap'}>
            <span>Егер сіз осы формада сіздің сұранысыңызға сәйкес келетін санатты немесе көшені таппаған болсаңыз, формаға санат/көше қосу үшін <a href="https://docs.google.com/forms/d/e/1FAIpQLSeibReftR_6KsUPkT1zpWTb7M-H_pdrAuoxbj8r9qRBvy1GKw/viewform?usp=sf_link" target={'_blank'}>өтініш қалдыра</a> аласыз.</span>
        </div>,
    },
    'Обязательное поле': {
        nameRu: "Обязательное поле",
        nameKz: "Міндетті өріс",
    },
    'Описание': {
        nameRu: "Описание",
        nameKz: "Сипаттама",
    },
    'Состояние': {
        nameRu: "Состояние",
        nameKz: "Жағдайы",
    },
    'Новый': {
        nameRu: "Новый",
        nameKz: "Жаңа",
    },
    'Б/У': {
        nameRu: "Б/У",
        nameKz: "Қолданылған",
    },
    'Возможна доставка': {
        nameRu: "Возможна доставка",
        nameKz: "Жеткізу мүмкін",
    },
    'Самовывоз': {
        nameRu: "Самовывоз",
        nameKz: "Алып кету",
    },
    'Имя заявителя': {
        nameRu: "Имя заявителя",
        nameKz: "Өтініш берушінің аты-жөні",
    },
    'Фамилия заявителя': {
        nameRu: "Фамилия заявителя",
        nameKz: "Өтініш берушінің тегі",
    },
    'Контакты для связи': {
        nameRu: "Контакты для связи",
        nameKz: "Байланыс үшін байланыстар",
    },
    'фото': {
        nameRu: "фото",
        nameKz: "фото",
    },
    'Адрес': {
        nameRu: "Адрес",
        nameKz: "Мекен-жайы",
    },
    'Введите текст для поиска': {
        nameRu: "Введите текст для поиска",
        nameKz: "Iздеу үшін мәтінді енгізіңіз",
    },
    'Пожалуйста, используйте только кириллические символы и цифры.': {
        nameRu: "Пожалуйста, используйте только кириллические символы и цифры.",
        nameKz: "Тек кирилл таңбалары мен сандарын қолданыңыз.",
    },
    'Квартира': {
        nameRu: "Квартира",
        nameKz: "Пәтер",
    },
    'Отметка на карте': {
        nameRu: "Отметка на карте",
        nameKz: "Картадағы белгі",
    },
    'Задать вопрос': {
        nameRu: "Задать вопрос",
        nameKz: "Сұрақ қою",
    },
    'Отмена': {
        nameRu: "Отмена",
        nameKz: "Бас тарту",
    },
    'Сбросить': {
        nameRu: "Сбросить",
        nameKz: "Қалпына келтіру",
    },
    'Подтверждение подачи объявления': {
        nameRu: "Подтверждение подачи объявления",
        nameKz: "Хабарландырудың берілгенін растау",
    },
    'Вы действительно хотите отправить объявление на рассмотрение?': {
        nameRu: "Вы действительно хотите отправить объявление на рассмотрение?",
        nameKz: "Сіз шынымен хабарландыруды қарауға жібергіңіз келе ме?",
    },
    'Подтверждение редактирования': {
        nameRu: "Подтверждение редактирования",
        nameKz: "Өңдеуді растау",
    },
    'Вы действительно хотите внести изменения?': {
        nameRu: "Вы действительно хотите внести изменения?",
        nameKz: "Сіз шынымен өзгеріс жасағыңыз келе ме?",
    },
    'Редактирование объявление': {
        nameRu: "Редактирование объявление",
        nameKz: "Өңдеу жарнама",
    },
    'Тема объявления': {
        nameRu: "Тема объявления",
        nameKz: "Хабарландыру тақырыбы",
    },
    'сбросить': {
        nameRu: "сбросить",
        nameKz: "қалпына келтіру",
    },
    'МКР': {
        nameRu: "МКР",
        nameKz: "Ш/А",
    },
    'Номер дома': {
        nameRu: "Номер дома",
        nameKz: "Үй нөмірі",
    },
    'Время проведения': {
        nameRu: "Время проведения",
        nameKz: "Өткізу уақыты",
    },
    'Объявление успешно отправлено на модерацию': {
        nameRu: "Объявление успешно отправлено на модерацию",
        nameKz: "Хабарландыру модерацияға сәтті жіберілді",
    },
    'Выбрать микрорайон': {
        nameRu: "Выбрать микрорайон",
        nameKz: "Шағын ауданды таңдаңыз",
    },
    'Выбрать улицу': {
        nameRu: "Выбрать улицу",
        nameKz: "Көшені таңдаңыз",
    },
    'Квартира/офис': {
        nameRu: "Квартира/офис",
        nameKz: "Пәтер / кеңсе",
    },
    'Выбрать категории': {
        nameRu: "Выбрать категории",
        nameKz: "Санаттарды таңдаңыз",
    },
    'Вес корзины': {
        nameRu: "Вес корзины",
        nameKz: "Себеттің салмағы",
    },
    'Время обращения': {
        nameRu: "Время обращения",
        nameKz: "Өтініш беру уақыты",
    },

}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },

{lanGs[''][lang]}
* */
