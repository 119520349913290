import React, {useEffect, useState} from "react";

import Map from "./Layer/Map";
import "./Style/Main.css";
import CardsPanel from "./Elements/cardsPanel";
import axios from "axios";

import NavEventButtons from "./Elements/NavEventButtons";
import CardItem from "./Elements/cardItem";

import CategoryCheckB from "./Elements/categoryCheckB";

import {useNavigate} from "react-router-dom";



const Main = ({distChoice, lang, toMark, setToMark, dataCat, data, setCatCardClick, setClickInitiative, setMapCatClick, tabBtn, setDistChoice, cardSelect, setCardSelect}) => {
    const [filterStatus, setFilterStatus] = useState(null);
    const [mapType, setMapType] = useState("cluster");
    const [cardData, setCardData] = useState(null);
    const [distShape, setDistShape] = useState(null);
    const [show, setShow] = useState(true);
    const navigate = useNavigate();

    window.addEventListener('popstate', function(event) {
        setDistChoice({ dist: "г. Алматы", id: 0 })
        navigate(`/`)
    });

    useEffect(() => {
        setCardSelect(null)
        setFilterStatus(null)
        axios.get(`/almaty-mon-repairworks-api/district-shapes/v2`).then(res => {setDistShape(res.data);});
    }, [distChoice]);

    return (
        <div className={"Main_wrapper"}>

            <Map
                mapType={mapType}
                data={data}
                toMark={toMark}
                setToMark={setToMark}
                distShape={distShape}
                distChoice={distChoice}
                lang={lang}
                setCardSelect={setCardSelect}
                cardSelect={cardSelect}
                setClickInitiative={setClickInitiative}
                filterStatus={filterStatus}
            /> {/* тело карты*/}
            <div className={"Main_control"}>
                <CategoryCheckB
                    dataCat={dataCat?.categories}
                    lang={lang}
                    setCardSelect={setCardSelect}
                    setCatCardClick={setCatCardClick}
                    setMapCatClick={setMapCatClick}
                    />

            </div>

            {data?.length > 0 && <CardsPanel
                data={data}
                cardSelect={cardSelect}
                setCardSelect={setCardSelect}
                setToMark={setToMark}
                setFilterStatus={setFilterStatus}
                lang={lang}
                setClickInitiative={setClickInitiative}
                show={show}
                filterStatus={filterStatus}
                tabBtn={tabBtn}
            />}

            <div className={`map_element_items ${show && ("nav_show_" + show)}`}>
                {cardData && cardSelect &&
                    <CardItem
                        cardData={cardData}
                        setToMark={setToMark}
                        lang={lang}
                        cardSelect={cardSelect}
                    />
                }
                {data?.length > 0 && <NavEventButtons
                    setShow={setShow}
                    show={show}
                    cardSelect={cardSelect}
                    setCardSelect={setCardSelect}
                    setToMark={setToMark}
                />}
            </div>
        </div>
    );
};

export default Main;
