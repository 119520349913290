import axios from "axios";
import {message} from "antd";
import {lanGs} from "./lang/lang-AddCharity";

import Ind1 from '../assets/image/1.svg'
import Ind2 from '../assets/image/2.svg'
import Ind3 from '../assets/image/3.svg'
import Ind4 from '../assets/image/4.svg'
import Ind5 from '../assets/image/5.svg'
import Ind6 from '../assets/image/6.svg'
import Ind7 from '../assets/image/7.svg'
import Ind8 from '../assets/image/8.svg'
import Ind9 from '../assets/image/9.svg'
import Ind10 from '../assets/image/10.svg'


export const numIcon=[
    Ind1, Ind2, Ind3, Ind4, Ind5, Ind6, Ind7, Ind8, Ind9, Ind10
]
const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

export const  declOfNum = (num, text_forms) =>  {
    let n = num !== 0?Math.abs(num) % 100: 0;

    let n1 = n % 10;
    if (n > 10 && n < 20 ) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1 ) { return text_forms[0]; }
    if(num === 0){ return text_forms[2]; }
    return text_forms[2];
}
export const uniqueItems = (datas, items, endItem) => {

    return datas.reduce((acc, st) => {
        if (acc.arr[st[items][endItem]]) // если данный объект уже был
            return acc; // ничего не делаем, возвращаем уже собранное
        acc.arr[st[items][endItem]] = true; // помечаем объект, как обработанный
        acc.result.push(st.status); // добавляем объект в массив объектов
        return acc; // возвращаем собранное
    }, {
        arr: {}, // здесь будут отмечаться обработанные объекты
        result: [] // здесь конечный массив объектов
    }).result; // получаем конечный массив объектов
}
export const uniqueItems2 = (datas, items, endItem) => {

    return datas.reduce((acc, st) => {
        if (acc.arr[st[items][endItem]]) // если данный объект уже был
            return acc; // ничего не делаем, возвращаем уже собранное
        acc.arr[st[items][endItem]] = true; // помечаем объект, как обработанный
        acc.result.push(st.status); // добавляем объект в массив объектов
        return acc; // возвращаем собранное
    }, {
        arr: {}, // здесь будут отмечаться обработанные объекты
        result: [] // здесь конечный массив объектов
    }).result; // получаем конечный массив объектов
}
export const distCenterSet = (dist) => {
    switch (dist) {
        case"1":
            return {
                center: [43.295, 76.801],
                zoom: 13
            };
        case"2":
            return {
                center: [43.252, 76.890],
                zoom: 14
            };
        case"3":
            return {
                center: [43.226, 76.832],
                zoom: 14
            };
        case"4":
            return {
                center: [43.184, 76.880],
                zoom: 13
            };
        case"5":
            return {
                center: [43.323, 76.901],
                zoom: 13
            };
        case"6":
            return {
                center: [43.180, 76.990],
                zoom: 12
            };
        case"7":
            return {
                center: [43.176, 76.799],
                zoom: 13
            };
        case"8":
            return {
                center: [43.347,76.973],
                zoom: 13
            };
        default: return {
            center: [43.221, 76.909],
            zoom: 12
        }

    }
};



export const MJKBool = (i) => {
    return i.categories[0].id === 4 && i.categories[1].id === 18;
};



export const  filterData = (data) =>  {
    // Фильтруем каждый объект в массиве
    let filteredData = data ? data?.map(item => ({
        ...item,
        // Фильтруем массив subs, оставляем только те объекты, у которых amount не равен 0
        subs: item.subs.filter(sub => sub.amount !== 0)
    }))
        .filter(item => item.subs.length > 0): null; // Фильтруем основной массив, оставляем только те объекты, у которых есть непустые subs

    return filteredData;
}
export const fioChange = (str) => {
    return str
        ?.split(" ")
        ?.map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
        ?.join(" ");
}
export const handleSearchMkr = (value, setOptionsMkr, form, lang) => {
    if (value) {
        const hasNonCyrillic = /[^а-яё]/i.test(value);
        if (!hasNonCyrillic) {
            axios
                .get(`/sc-initiatives/api/v1/ref/micro-district?q=${value}`)
                .then((response) => {
                    let transformedOptions = response.data.map((item) => ({
                        value: item.id,
                        label: item.name[lang],
                    }));
                    setOptionsMkr(transformedOptions);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            message.warning(lanGs['Пожалуйста, используйте только кириллицу для ввода.'][lang]);
            setOptionsMkr([]);
            form.setFieldsValue({microDistrictId: ''});
        }
    } else {
        setOptionsMkr([]);
    }
};
export const handleSearchStr = (value, setOptionsStr, lang, form) => {
    if (value) {
        const hasNonCyrillic = /[^а-яё]/i.test(value);
        if (!hasNonCyrillic) {
            axios
                .get(`/sc-initiatives/api/v1/ref/street?q=${value}`)
                .then((response) => {
                    let transformedOptions = response.data.map((item) => ({
                        value: item.id,
                        label: item.name[lang],
                    }));
                    setOptionsStr(transformedOptions);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            message.warning('Пожалуйста, используйте только кириллицу для ввода.');
            setOptionsStr([]);
            form.setFieldsValue({streetId: ''});
        }
    } else {
        setOptionsStr([]);
    }
};
