import React, {useEffect, useState} from 'react';
import './ProfilePage.css'
import {Button, Form, message, Tabs, Tooltip} from "antd";
import 'moment/locale/ru'
import 'moment/locale/kk'
import {DatabaseOutlined, ExclamationCircleOutlined, FacebookOutlined, FormOutlined, InstagramOutlined, QuestionCircleOutlined,} from "@ant-design/icons";
import {ReactComponent as Heart} from '../../assets/icons/heart.svg'
import defUser from '../../assets/defUser.webp'
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {fioChange} from "../../utils/helper";
import {lanGs} from '../../utils/lang/lang-ProfilePage'

const ProfilePage = ({lang, userI, myAds, setClickInitiative, setResU,setTabsKey}) => {
    const token = Cookies.get('jwt')
    const [data, setData] = useState(userI)
    const navigate = useNavigate();

    useEffect(() => {
        setTabsKey(0)
        !data && setData(null);

        }, [data])
    let SendToArch = (archId) => {
        userI  && token && axios({
            method: "put", url: `/sc-initiatives/api/v1/initiative/status-update`,
            data: {"initiativeId": archId, "statusId": 3, "comment": "Пользователь отправил в архив"},
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        }).then((res) => {
            message.success(lanGs['Перенесено в архив'][lang], 3);
            setResU(true)
        }).catch(function (error) {
            message.error(lanGs['Что то пошло не так, попробуйте позднее'][lang], 3);
            console.log('error ProfilePage advert/deactivate', error)
        });
    }

    let filterAds = (data, item, isMyList = true, isCompany = false) => {
        if (isMyList) {
            return data?.filter(i => i.status?.id === item).map((it, index) => {
                return <div className={'profile_ads_item'} key={index}>
                    <div className={'profile_ads_click'} onClick={() => {setClickInitiative(it.id)}}>
                        <div className={'profile_ads_info_text'}>
                            <Tooltip placement="top" color={'#146eb0'} overlayClassName={'profile_tooltip_wrapper'} title={it?.name}>
                                <span className={'p_id'}>{lanGs['Номер инициативы'][lang]} {it?.id}</span>
                            </Tooltip>
                            <div className={'p_cat'}>{it.categoryInitiative?.name[lang]} / {it.categoryWork?.name[lang]}</div>
                            <span className={'p_date'}>{lanGs['Дата регистрации'][lang]}: {it?.date}</span>
                            <span className={'p_voteAmount'}> {it.voteAmount} <Heart /></span>
                        </div>
                    </div>
                    {isMyList &&  <div className="profile_ads_info_btn">
                        {(it?.status?.id === 2 ) && <Tooltip title={lanGs["Редактировать"][lang]}>
                            <FormOutlined onClick={() => {navigate(`/edit-initiatives/${it.id}`);}}/></Tooltip>}
                        { it?.status?.id === 1 && <Tooltip title={lanGs["В архив"][lang]}>
                            <DatabaseOutlined onClick={() => SendToArch(it.id)}/></Tooltip>}
                    </div>}
                </div>
            })
        }
    }

    return (
        <div className={`ProfilePage_wrapper`}>
            <div className={`ProfilePage_wrap`}>
                <div className="profile_info">
                    <img src={data?.profileImg ? data?.profileImg : defUser} className={'profile_info_img'}/>
                    <div className={'profile_info_items'}>
                        <div className="profile_info_item date">
                            <div className={'profile_info_item_'}>
                                <span className={'profile_info_item_defText'}>{lanGs['Профиль'][lang]}</span>
                                <Tooltip title={lanGs["Редактировать профиль"][lang]}>
                                    <span className={'profile_info_item_edit'} onClick={() => navigate('/edit-profile')}><FormOutlined/></span>
                                </Tooltip>
                            </div>
                        </div>
                        {data?.company &&
                            <div className="profile_info_item name"><span className="profile_item_title">{lanGs['Компания'][lang]}:</span>
                                <span className={'profile_item_text'}>{data.company}</span></div>}
                        {data?.firstName &&
                            <div className="profile_info_item name"><span className="profile_item_title">{lanGs['Имя пользователя'][lang]}:</span>
                                <span className={'profile_item_text'}>{data?.firstName} {data?.lastName}</span></div>}
                        {data?.oked?.id && <div className="profile_info_item oked">
                            <Tooltip title={lanGs['Основной код экономической деятельности'][lang]}>
                                <span className="profile_item_title">{lanGs['ОКЭД'][lang]}:</span></Tooltip>
                            {data?.oked?.name ? <Tooltip title={data?.oked?.name[lang]}>
                                    <span className={' stroke_round_1'} style={{cursor: "pointer"}}>{data?.oked.name[lang]}</span></Tooltip> :
                                <span className={'profile_item_text'}>{lanGs['Не найден'][lang]}</span>}
                        </div>}
                        {data?.supervisor && <div className="profile_info_item gender">
                            <span className="profile_item_title">{lanGs['ФИО руководителя'][lang]}</span>
                            <span className={'profile_item_text'}>{data?.supervisor ? fioChange(data?.supervisor) : lanGs['не указан'][lang]}</span>
                        </div>}
                        {data?.gender && <div className="profile_info_item gender">
                            <span className="profile_item_title">{lanGs['Пол'][lang]}:</span>
                            <span className={'profile_item_text'}>{data?.gender ? data?.gender === 'MALE' ? lanGs['Мужской'][lang] : lanGs['Женский'][lang] :
                                lanGs['не указан'][lang]}</span>
                        </div>}
                        {data?.bin && <div className="profile_info_item iin">
                            <span className="profile_item_title">{lanGs['ИИН / БИН'][lang]}:</span>
                            <span className={'profile_item_text'}>{data.bin}</span>
                        </div>}
                        <div className="profile_info_item contact">
                            <span className="profile_item_title">{lanGs['Контактный номер'][lang]}:</span>
                            <span className={'profile_item_text'}>{data?.phone}</span>
                        </div>
                        <div className="profile_info_item address">
                            <span className="profile_item_title">{lanGs['Адрес'][lang]}</span>
                            <div className={'profile_item_text'}>{(data?.district?.name[lang] || data?.microDistrict || data?.street
                                || data?.building || data?.apartment) ?
                                <div className={'profile_address_items'}>
                                    {data?.district?.name[lang] && <span>{data?.district?.name[lang]}</span>}
                                    {data?.microDistrict?.name && <span>{data?.microDistrict?.name[lang]},</span>}
                                    {data?.street?.name && <span>{data?.street?.name[lang]},</span>}
                                    {data?.building && <span>{data?.building},</span>}
                                    {data?.apartment && <span>{data?.apartment}</span>}
                                </div> : lanGs['не указан'][lang]}</div>
                        </div>
                        <div className="profile_info_item email"><span className="profile_item_title">Email:</span>
                            <span className={'profile_item_text'}>{data?.email}</span></div>
                        {(data?.facebook || data?.instagram) && <div className="profile_info_item networks">
                            <span className="profile_item_title">{lanGs['Социальные сети'][lang]}:</span>
                            <div className="social_network">
                                {data?.facebook && <Tooltip title="Facebook"><a href={data.facebook} title={'Facebook'} target={'_blank'}>
                                    <FacebookOutlined/></a></Tooltip>}
                                {data?.instagram && <Tooltip title="Instagram"><a href={data.instagram} title={'Instagram'} target={'_blank'}>
                                    <InstagramOutlined/></a></Tooltip>}</div>
                        </div>}
                        <div className={'Profile_control_item'}>
                            <div className={'Profile_control_info'}>
                                <Tooltip title="FAQ"><span onClick={() => navigate('/faq')}><ExclamationCircleOutlined/></span></Tooltip>
                                <Tooltip title={lanGs["Задать вопрос"][lang]}><span><QuestionCircleOutlined/></span></Tooltip></div>
                            <Form.Item className={'btn_submit_item_wrap'}>
                                <Button className={'btn_add_item'} onClick={() => navigate('/add-initiatives')}>{lanGs['Новая инициатива'][lang]}</Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="profile_ads">
                    <span className={'profile_ads_title_block'}>{lanGs['Инициативы'][lang]}</span>
                    <div className={'profile_ads_items'}>
                        <Tabs  type="card"
                              items={[
                                  {label: lanGs[`Активные инициативы`][lang], key: '1',
                                      children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 1)}</div>,},
                                  {label: lanGs[`На проверке`][lang], key: '5',
                                      children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 5)}</div>,},
                                  {label: lanGs['На доработку'][lang], key: '2',
                                      children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 2)}</div>,},
                                  {label: lanGs['Отказ'][lang], key: '3',
                                      children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 3)}</div>,},
                                  {label: lanGs[`Архив`][lang], key: '4',
                                      children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 4)}</div>,},]}/>
                    </div>
                </div>
                <div className="profile_feedback">
                    <div className="profile_ads_items">
                        <Tabs  type="card" items={[{label: lanGs['Принятые'][lang], key: '6',
                            children: <div className={'profile_ads_item_wrap'}>{filterAds(myAds, 6)}</div>,},]}/>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default ProfilePage;

