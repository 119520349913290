import React, { useEffect, useState } from 'react';
import './EditProfilePage.css';
import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Select,
    Tooltip,
    Upload,
} from 'antd';
import InputMask from 'react-input-mask';
import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { lanGs } from '../../utils/lang/lang-EditProfilePage';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import ImgCrop from 'antd-img-crop';
import {fioChange, handleSearchMkr, handleSearchStr} from '../../utils/helper';

dayjs.locale('ru');
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};
const EditProfilePage = ({ lang, userI, setUserI }) => {
    const token = Cookies.get('jwt');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [optionsStr, setOptionsStr] = useState([]);
    const [optionsMkr, setOptionsMkr] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataDist, setDataDist] = useState(null);
    const [sendForm, setSendForm] = useState(null);

    useEffect(() => {
        userI?.profileImg && setImageUrl(userI?.profileImg);
        axios.get(`/sc-initiatives/api/v1/ref/district`).then((res) => {
            setDataDist(res.data);
        });
    }, [userI]);
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = (values) => {
        setSendForm({...values, profileImg: imageUrl,});
        setIsModalOpen(true);
    };
    const onFinishOk = (values) => {
        setIsModalOpen(true);
        const updatedData = {
            ...sendForm,
            districtId: sendForm.districtId ? typeof sendForm.districtId === 'string'? userI.district.id:sendForm.districtId: null,
            streetId: typeof sendForm?.streetId === 'number'? sendForm.streetId : userI.street?.id ? userI.street.id : null,
            microDistrictId: typeof sendForm?.microDistrictId === 'number' ? sendForm.microDistrictId : userI.microDistrict?.id ? userI.microDistrict.id : null,
            birthdate: !userI.company ? sendForm.birthdate ? dayjs(sendForm?.birthdate).format('DD.MM.YYYY') : null: null,
            building: sendForm?.building?.length > 0? sendForm.building: null,
            apartment: sendForm?.apartment?.length > 0? sendForm.apartment: null,
            bin: undefined,
            email: undefined,
            company: undefined,
            supervisor: undefined,
            facebook: null,
            instagram: null,
            gender: !userI.company ? sendForm.gender !== undefined? sendForm.gender: null: undefined
        };
        axios({
            method: "put",
            url: "/sc-initiatives/api/v1/user/profile",
            data: updatedData,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setIsModalOpen(false);
                axios({
                    method: "get",
                    url: `/sc-initiatives/api/v1/user/profile`,
                    headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
                })
                    .then((res) => {
                        setUserI(res.data)
                        Cookies.set('UserID', JSON.stringify(res.data))
                        message.success(lanGs['Данные профиля успешно изменены'][lang],3).then()
                    })
                    .catch(function (error) {
                        message.error(lanGs['Что то пошло не так, попробуйте позднее'][lang],3).then()
                        console.log('error EditProfilePage user/profile', error)
                    })

            })
            .catch(function (error) { });
        setIsModalOpen(false);
    };
    const props = {
        name: 'file',
        method: 'post',
        action: '/cdn/api/v1/upload/initiative/avatar',
        headers: {'api-key': 'Njg3MTU0MjYxLCJleHAiO'},
        onChange(info) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                setLoading(false);
                setImageUrl(info.file.response.url);
            }
        },
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const resetForm = () => {
        form.resetFields();
    };
    const validatePhoneNumber = (_, value) => {
        if (!value) {
            return Promise.reject(`${lanGs['Не заполнено'][lang]}`);
        }
        if (!/^\+7\(\d{3}\)\d{3} \d{2} \d{2}$/.test(value)) {
            return Promise.reject(`${lanGs['Введите телефонный номер в формате +7(777)777 77 77'][lang]}`);
        }
        return Promise.resolve();
    };
    return (
        <div className={`EditProfilePage_wrapper`}>
            <div className={`EditProfilePage_wrap`}>
                <Form
                    layout="horizontal"
                    className={'EditProfilePage_form'}
                    onFinish={onFinish}
                    initialValues={{
                        districtId: userI?.district ? userI.district.name[lang]: null,
                        firstName: userI?.firstName && userI.firstName,
                        lastName: userI?.lastName && userI.lastName,
                        profileImg: userI?.profileImg,
                        phone: userI?.phone && userI.phone.slice(0,16),
                        birthdate: userI?.birthdate ? dayjs(userI?.birthdate, 'DD.MM.YYYY'): null,
                        gender: userI?.gender,
                        microDistrictId: userI?.microDistrict ? userI.microDistrict.name[lang]: null,
                        streetId: userI?.street ? userI.street.name[lang]:null,
                        building: userI?.building && userI.building,
                        apartment: userI?.apartment && userI.apartment,
                        bin: userI?.bin,
                        email: userI?.email && userI.email,
                        company:  userI?.company? userI.company:null,
                        supervisor:  userI?.supervisor? fioChange(userI.supervisor):null
                    }}
                >
                    <div className={'EditProfilePage_photo_social'}>
                        <div className={'Edit_photo'}>
                            <Form.Item valuePropName={"fileList"} getValueFromEvent={normFile}>
                                <ImgCrop
                                    rotationSlider
                                    aspect={4 / 3}
                                    string={'white'}
                                    minZoom={0}
                                    quality={1}
                                    showReset
                                    resetText={`${lanGs['Сбросить'][lang]}`}
                                    cropBoxMovable={true}
                                >
                                    <Upload name="profileImg" listType="picture-card" className="avatar-uploader" showUploadList={false}{...props}>
                                        {imageUrl ? (<img src={imageUrl} alt="profileImg" style={{width: '100%',}}/>) : (uploadButton)}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </div>
                        <div className={'Edit_social'}>
                            {!userI?.company && <Form.Item label={lanGs['Фамилия'][lang]} name={'lastName'} rules={[{required: true, message: lanGs['Обязательное' +
                                ' поле'][lang],},]}>
                                <Input size={'large'} maxLength={20}/>
                            </Form.Item>}
                            {!userI?.company && <Form.Item label={lanGs['Имя'][lang]} name={'firstName'} rules={[{required: true, message: lanGs['Обязательное' +
                                ' поле'][lang],},]}>
                                <Input size={'large'} maxLength={20}/>
                            </Form.Item>}
                            {!userI?.company && <Form.Item label={lanGs["Дата рождения"][lang]} className={'DatePicker_item'} name={'birthdate'}>
                                <DatePicker size={'large'} format="DD.MM.YYYY"/>
                            </Form.Item>}
                        </div>
                    </div>

                    <div className={'Edit_user'}>
                        {!userI?.company && <Form.Item label={lanGs["Пол"][lang]} name={'gender'} >
                            <Select size={'large'} allowClear>
                                <Select.Option value="MALE">{lanGs['Мужской'][lang]}</Select.Option>
                                <Select.Option value="FEMALE">{lanGs['Женский'][lang]}</Select.Option>
                            </Select>
                        </Form.Item>}

                        <Form.Item label={lanGs['ИИН/БИН'][lang]} name={'bin'}>
                            <Input size={'large'} disabled maxLength={12}/>
                        </Form.Item>
                        <Form.Item
                            label={lanGs['Телефон'][lang]}
                            name={'phone'}
                            rules={[{ validator: validatePhoneNumber }]}
                            size="large"
                        >
                            <InputMask mask="+7(999)999 99 99" maskChar={null}>
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        size="large"
                                        className={'Password_type_input'}
                                        maxLength={16}
                                        placeholder={userI?.phone && userI.phone.slice(0, 16)}
                                    />
                                )}
                            </InputMask>
                        </Form.Item>

                        <Form.Item label={'Email'} name={'email'} rules={[{required: true, message: lanGs['Обязательное поле'][lang],},]}>
                            <Input size={'large'} disabled maxLength={50}/>
                        </Form.Item>
                        {userI?.company && <Form.Item label={lanGs['Компания'][lang]} name={'company'} >
                            <Input size={'large'} disabled />
                        </Form.Item>}
                        {userI?.company && <Form.Item label={lanGs['Руководитель'][lang]} name={'supervisor'} >
                            <Input size={'large'} disabled />
                        </Form.Item>}
                    </div>
                    <div className={'Edit_address'}>
                        <Divider orientation="left"> <span>{lanGs['Адрес'][lang]}</span></Divider>
                        <Form.Item className={'street_style_input'} label={lanGs["Район"][lang]} name={'districtId'}>
                            <Select style={{width: '100%',}} options={dataDist?.map(i => {
                                return {value: i.id, label: i.name[lang]}
                            })} size="large" placeholder={lanGs["Выберите район"][lang]} allowClear/>
                        </Form.Item>
                            <Form.Item name="microDistrictId" label={lanGs["МКР"][lang]} className={'street_style_input'} autoComplete="disabled">
                                <Select showSearch  size="large" placeholder={lanGs['Введите текст для поиска'][lang]}
                                        notFoundContent={null} filterOption={false} onSearch={(value) => handleSearchMkr(value, setOptionsMkr, form, lang)}
                                        onChange={(value) => {
                                            form.setFieldsValue({microDistrictId: value});
                                        }} options={optionsMkr}/>
                            </Form.Item>

                            <Form.Item label={lanGs['Улица'][lang]} name={'streetId'} className={'street_style_input'} autoComplete="disabled">
                                <Select showSearch  size="large" placeholder={lanGs['Введите текст для поиска'][lang]} notFoundContent={null}
                                        filterOption={false}   onSearch={(value) => handleSearchStr(value, setOptionsStr, lang, form)} onChange={(value) => {
                                    form.setFieldsValue({streetId: value});
                                }} options={optionsStr}
                                />
                            </Form.Item>

                        <Form.Item label={lanGs['Дом'][lang]} name={'building'} autoComplete="disabled" className={'building_input'}>
                            <Input size={'large'} autoComplete="disabled" maxLength={5}/>
                        </Form.Item>
                        <Form.Item label={lanGs['Квартира'][lang]} name={'apartment'} className={'building_input'}>
                            <Input size={'large'} maxLength={5}/>
                        </Form.Item>
                    </div>
                    <div className={'Edit_control_item'}>
                        <div className={'Edit_control_info'}>
                            <Tooltip title="FAQ"><span><ExclamationCircleOutlined/></span></Tooltip>
                            <Tooltip title={lanGs["Задать вопрос"][lang]}><span><QuestionCircleOutlined/></span></Tooltip>
                        </div>
                        <Form.Item className={'btn_submit_item_wrap'}>
                            <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Отправить'][lang]}</Button>
                            <Button className={'btn_submit_item'} onClick={() => navigate('/profile')}>{lanGs['Назад'][lang]}</Button>
                            <Tooltip title={lanGs["Сбросить"][lang]}><Button htmlType="reset" onClick={resetForm}><RedoOutlined/></Button></Tooltip>
                        </Form.Item>
                    </div>
                </Form>
                <Modal title={lanGs["Подтверждение изменений"][lang]} open={isModalOpen} onOk={onFinishOk} onCancel={handleCancel} centered
                       className={'AddCharity_modal'}>
                    <p>{lanGs['Вы действительно хотите внести изменения в профиль?'][lang]}</p>
                </Modal>
            </div>
        </div>
    );
};

export default EditProfilePage;

/*
  "facebook": "https://www.facebook.com/almatydc.kz/",
    "instagram": "https://www.instagram.com/almaty_city/",
* */
