import React, {useEffect, useState} from 'react';
import './AddInitiatives.css'
import {AutoComplete, Button, Cascader, Form, Input, message, Modal, Select, Upload} from "antd";
import {RedoOutlined, UploadOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import MapBlock from "../common/ui/MapBlock";
import BackBlock from "../common/element/BackBlock";
import axios from "axios";
import Cookies from "js-cookie";
import {lanGs} from "../utils/lang/langAddInitiatives";


const EditInitiatives = ({lang, setCatCardClick, setResetCardMain, setData, setDistChoice}) => {

    const [placemarkGeometry, setPlacemarkGeometry] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sendForm, setSendForm] = useState(null);
    const [dataCat, setDataCat] = useState(null);
    const [dataWork, setDataWork] = useState(null);

    const [dataObj, setDataObj] = useState(null);

    const [dataSource, setDataSource] = useState(null);
    const [dataDist, setDataDist] = useState(null);
    const [initData, setInitData] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const [allowedFileExtensions] = useState(['pdf', 'xlsx','xls', 'doc','docx', 'jpg', 'jpeg', 'png']); // Допустимые расширения файлов

    const [form] = Form.useForm();
    const location = useLocation();
    const token = Cookies.get('jwt')
    const {id} = useParams();

    const onFinish = (values) => {
        // Преобразуем InitiativesObj, если оно является массивом
        const dataToSend = {
            ...values,
            InitiativesObj: Array.isArray(values.InitiativesObj) ? values.InitiativesObj[0] : values.InitiativesObj,
        };

        setSendForm(dataToSend); // Сохраняем данные для отправки
        setIsModalOpen(true);

    };


    const [fileList, setFileList] = useState([]);


    useEffect(() => {

        if (!token && id) {
            messageApi.open({
                type: 'error',
                content: `${lanGs['Unauthorized'][lang]}`,
                duration: 5,
            },);
            setTimeout(function () {

                Cookies.remove('jwt')
                setTimeout(function() {

                    Cookies.remove('jwt')
                    navigate(`/login?redirect=/edit-initiatives/${id}`)
                }, 1000)
            }, 1000)
        }
        token && id && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/initiative/detail/${id}`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setInitData(res.data);
                setPlacemarkGeometry(res.data.geom?.coordinates?res.data.geom?.coordinates.reverse(): null)
                setFileList(res.data.files)

            })
            .catch(function (error) {


            });

    }, [id]);
    useEffect(() => {

        if (token) {
            axios.get(`/sc-initiatives/api/v1/ref/cat-initiative`).then((res) => {
                setDataCat(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/cat-work`).then((res) => {
                setDataWork(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/cat-object`).then((res) => {
                setDataObj(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/fin-source`).then((res) => {
                setDataSource(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/district`).then((res) => {
                setDataDist(res.data);
            })

        }
    }, [token]);
    const onFinishOk = (values) => {
        setIsModalOpen(true);
        const streetId = form.getFieldValue('streetId');

        id && sendForm && axios({
            method: "put",
            url: "/sc-initiatives/api/v1/initiative/update",
            data:{
                'id': Number(id),
                "districtId": sendForm.InitiativesDist,
                "streetId": streetId? streetId: 11,
                "building": sendForm?.InitiativesBuild ? sendForm.InitiativesBuild: null,
                "categoryInitiativeId": sendForm.InitiativesCat? sendForm.InitiativesCat: null,
                "categoryObjectId": sendForm.InitiativesObj ? sendForm?.InitiativesObj: null,
                "categoryWorkId": sendForm.InitiativesWork? sendForm.InitiativesWork: null,
                "finSourceId": sendForm.source? sendForm.source: null,
                "name": sendForm.itiativesShortName ? sendForm.itiativesShortName : null,
                "geom": placemarkGeometry && placemarkGeometry.length > 0? {
                    "type": "Point",
                    "coordinates": [placemarkGeometry[1], placemarkGeometry[0]]
                }:null ,
                "description": sendForm.itiativesName? sendForm.itiativesName: null,
                "suggestion": sendForm.offer? sendForm.offer: null,
                files: fileList.map((file) => ({
                    url: file.url, // изменено с file.response.url на file.url
                    type: file.type,
                    filename: file.filename
                }))
            },
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setIsModalOpen(false);
                message.success(`${lanGs['SEND_INITIATIVES'][lang]}`, [3]).then(navigate(-1), setResetCardMain(true))


            })
            .catch(function (error) {
                if (error?.request?.statusText === 'Unauthorized') {
                    message.error(`${lanGs['Unauthorized'][lang]}`, [3])
                        .then(Cookies.remove('jwt'),Cookies.remove('roles'), navigate('/login?redirect=/add-initiatives'))

                }
                else if (error?.request?.response.includes('Новый')) {message.error(`${lanGs['onModer'][lang]}`, [3]).then(navigate('/'))}

            });

        setIsModalOpen(false);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        const newFileList = e.fileList.map((file) => {
            if (file.response) {
                return {
                    uid: file.uid,
                    name: file.response.filename,
                    status: 'done',
                    url: file.response.url,
                    type: file.type,
                    filename: file.response.filename,
                };
            } else {
                return file;
            }
        });

        setFileList(newFileList);
        return e && e.fileList;
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const props = {
        name: 'file',
        method: 'post',
        action: '/cdn/api/v1/upload/initiative/docs',
        headers: { authorization: `Bearer ${token}`, 'api-key': 'Njg3MTU0MjYxLCJleHAiO' },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                const { response } = info.file;
                setFileList((prevFileList) => {
                    const updatedFileList = prevFileList.filter(file => file.uid !== info.file.uid);
                    updatedFileList.push({
                        uid: info.file.uid,
                        name: info.file.name,
                        status: info.file.status,
                        url: response && response.url,
                        type: info.file.type,
                        filename: info.file.name, // Добавляем filename
                    });
                    return updatedFileList;
                });
            }
            else if (info.file.status === 'removed') {

                setFileList((prevFileList) => prevFileList.filter(file => file.uid !== info.file.uid));
            }
            else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

            // Добавьте этот блок кода для обработки случая, когда файл был удален
            if (info.file.status === 'removed') {
                setFileList((prevFileList) => {
                    return prevFileList.map(file => {
                        if (file.uid === info.file.uid) {
                            return {
                                ...file,
                                // filename: info.file.name, // Сохраняем filename
                            };
                        }
                        return file;
                    });
                });
            }
        },
        onRemove: (file) => {
            handleRemove(file);
        },
        defaultFileList: initData?.files?.map((i, index) => {
            return {
                uid: index,
                name: i.filename,
                status: 'done',
                url: i.url,
                type: i.type,
                filename: i.filename,
            };
        }),
    };


    const [options, setOptions] = useState([]);
    const handleSearch = (value) => {
        if (value) {
            // Проверяем, содержит ли введенное значение символы, не являющиеся кириллицей
            const hasNonCyrillic = /[^а-яё]/i.test(value);

            if (!hasNonCyrillic) {
                axios.get(`/sc-initiatives/api/v1/ref/street?q=${value}`)
                    .then((response) => {
                        const transformedOptions = response.data.map((item) => ({
                            value: item.name[lang],
                            label: item.name[lang],
                            id: item.id,
                        }));
                        setOptions(transformedOptions);
                    })
                    .catch((error) => {
                        // Обработайте ошибку, если необходимо
                    });
            } else {
                // Если введен текст, содержащий символы не из кириллицы, выводим сообщение, очищаем список опций и поле ввода
                message.warning('Пожалуйста, используйте только кириллицу для ввода.');
                setOptions([]);
                form.setFieldsValue({ 'InitiativesStr': '' });
            }
        } else {
            setOptions([]);
        }
    };
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    };

    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    return (
        <div className={'AddInitiatives_wrapper'}>
            {contextHolder}
            <BackBlock lang={lang} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
            {token && <div className={'AddInitiatives_wrap'}>
                <div className={'AddInitiatives_leftBlock'}>
                    {initData && <Form name="nest-messages"
                                       onFinish={onFinish}
                                       style={{maxWidth: 600,}}
                                       layout="vertical"
                                       autoComplete="off"
                                       initialValues={{
                                           // Устанавливаем начальные значения полей из переданного объекта initData
                                           InitiativesCat: initData?.categoryInitiative?.id,
                                           InitiativesWork: initData?.categoryWork.id,
                                           InitiativesObj: initData?.categoryObject.id,
                                           InitiativesDist: initData?.district.id,
                                           InitiativesStr: initData?.street?.name && initData?.street?.name[lang],
                                           streetId: initData?.street?.id,
                                           InitiativesBuild: initData?.building,
                                           itiativesShortName: initData?.name,
                                           itiativesName: initData?.description,
                                           offer: initData?.suggestion,
                                           source: initData?.finSource.id,
                                       }}
                    >
                        <Form.Item noStyle>
                            <div className={'AddInitiatives_title_wrap'}>
                                <span className={'AddInitiatives_title_text'}>{lanGs['Редактирование инициативы'][lang]} №{initData.id}</span>
                                <Button htmlType="reset" onClick={() => setPlacemarkGeometry(null)}><RedoOutlined/></Button>
                            </div>
                        </Form.Item>

                        <Form.Item label={lanGs["Категория инициативы"][lang]} name="InitiativesCat" hasFeedback
                                   rules={[{required: true, message: lanGs['Не выбрано'][lang],},]} className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} size="large" options={dataCat?.map(i => {
                                return {value: i.id, label: i.name[lang]}
                            }).sort((a, b) => a.label.localeCompare(b.label))}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Категория работ"][lang]} name="InitiativesWork" hasFeedback rules={[{required: true, message: lanGs['Не' +
                            ' выбрано'][lang],},]}
                                   className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} options={dataWork?.map(i => {
                                return {value: i.id, label: i.name[lang]}
                            }).sort((a, b) => a.label.localeCompare(b.label))} size="large"/>
                        </Form.Item>

                        {/*<Form.Item label="Категория объектов" name="InitiativesObj" hasFeedback rules={[{required: true, message: lanGs['Не выбрано'][lang],},]}
                                   className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} options={dataObj?.map(i => {
                                return {value: i.id, label: i.name[lang]}
                            })} size="large"/>
                        </Form.Item>*/}
                        <Form.Item
                            label={lanGs["Категория объектов"][lang]}
                            name="InitiativesObj"
                            hasFeedback
                            rules={[{ required: true, message: lanGs['Не выбрано'][lang] }]}
                            className={'AddInitiatives_line'}
                        >
                            <Cascader
                                options={dataObj?.map((i) => {
                                    return { value: i.id, label: i.name[lang] };
                                }).sort((a, b) => a.label.localeCompare(b.label))}
                                placeholder={lanGs['Поиск категории объекта'][lang]}
                                showSearch={{
                                    filter,
                                }}
                                size="large"
                                onChange={(value) => {
                                    form.setFieldsValue({ InitiativesObj: value[0] }); // Устанавливаем числовое значение
                                }}
                            />
                        </Form.Item>
                        <div className={'item_three_block'}>
                            <Form.Item className={'AddInitiatives_line'} label={'Район'} name={'InitiativesDist'}>
                                <Select style={{width: '100%',}} options={dataDist?.map(i => {
                                    return {value: i.id, label: i.name[lang]}
                                })} size="large"/>
                            </Form.Item>


                            <Form.Item label={lanGs['Улица'][lang]} name={'InitiativesStr'}>
                                <AutoComplete
                                    size="large"
                                    options={options}
                                    onSearch={handleSearch}
                                    placeholder="Введите текст для поиска"
                                    onSelect={(value, option) => {
                                        form.setFieldsValue({'InitiativesStr': option.label});
                                        form.setFieldsValue({'streetId': option.id});
                                    }}
                                    onInput={(e) => {
                                        const inputValue = e.target.value;
                                        const filteredInput = inputValue.replace(/[^а-яА-ЯёЁ\s]/g, '');

                                        if (inputValue !== filteredInput) {
                                            message.warning('Пожалуйста, используйте только кириллические символы.');
                                        }

                                        e.target.value = filteredInput;
                                    }}
                                />
                            </Form.Item>


                            <Form.Item label={lanGs['№ дома'][lang]} name={'InitiativesBuild'}>
                                <Input size="large" className={''} rules={[{max: 20, message: lanGs['Лимит символов:'][lang] + ' 20',},]}/>
                            </Form.Item>
                        </div>

                        <Form.Item label={lanGs['Краткое название инициативы'][lang]} name="itiativesShortName"
                                   rules={[
                                       {required: true, message: lanGs['Не заполнено'][lang],},
                                       {max: 50, message: lanGs['Лимит символов:'][lang] + ' 50',},
                                   ]} className={'AddInitiatives_line'}>
                            <Input size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Подробное описание инициативы"][lang]}
                                   name="itiativesName"
                                   rules={[
                                       {required: true, message: lanGs['Не заполнено'][lang],},
                                       {max: 1000, message: lanGs['Лимит символов:'][lang] + ' 1000',},
                                   ]}
                                   className={'AddInitiatives_line'}>
                            <TextArea size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Предлагаемые пути решения"][lang]} name="offer" rules={[
                            {required: true, message: lanGs['Не заполнено'][lang],},
                            {max: 2000, message: lanGs['Лимит символов:'][lang] + ' 2000',},
                        ]} className={'AddInitiatives_line'}>
                            <TextArea size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Источник финансирования"][lang]}
                                   name="source"
                                   hasFeedback
                                   rules={[{required: true, message: lanGs['Не выбрано'][lang],},]}
                                   className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} options={dataSource?.map(i => {
                                return {value: i.id, label: i.name[lang]}
                            })} size="large"/>
                        </Form.Item>

                        <Form.Item
                            name="upload"
                            label={lanGs['Добавить вложения'][lang]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            extra={lanGs['Объем ф'][lang]}
                        >
                            <Upload
                                name="docs"
                                {...props}
                                fileList={initData?.files} // Передаем initData.files в defaultFileList
                                accept={allowedFileExtensions.map((extension) => `.${extension}`).join(',')}
                            >
                                <Button icon={<UploadOutlined/>}>
                                    {lanGs['Выбрать вложение'][lang]}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item className={'btn_submit_item_wrap'}>
                            <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Отправить'][lang]}</Button>
                        </Form.Item>
                    </Form>}
                </div>
                <div className={'AddInitiatives_rightBlock'}>
                    <MapBlock
                        initData={initData}
                        placemarkGeometry={placemarkGeometry}
                        setPlacemarkGeometry={setPlacemarkGeometry}
                        lang={lang}

                    />

                </div>
                <Modal title={lanGs["Подтверждение подачи инициативы"][lang]} open={isModalOpen} onOk={onFinishOk} onCancel={handleCancel} centered
                       className={'AddInitiatives_modal'}>
                    <p>{lanGs['Вы действительно хотите отправить заявку на рассмотрение?'][lang]}</p>
                </Modal>
            </div>}
        </div>
    );
};
export default EditInitiatives;
