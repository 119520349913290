import React, {useState} from 'react';
import './Dashboard.css'
import ScrollBlock from "../common/ui/ScrollBlock";
import People from '../assets/image/people.png'
import BgBanner from '../assets/image/banerBg.png'
import CatAll from '../assets/image/cat_all.webp'
import moment from 'moment'
import {lanGs} from '../utils/lang/langDashboard'
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {CompassOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {sendMetrik} from "../utils/Analitics/metriks";
import {Button, message, Popover} from "antd";



const Dashboard = ({lang, setCatCardClick, clickInitiative, setClickInitiative, to10Data, dataCat, tabBtn, setTabBtn, totalInfo, dataCardPas, setResetTable}) => {
    const [open, setOpen] = useState(false);
    const hide = () => {
        setOpen(false);

    };
    const navigate = useNavigate();
    const token = Cookies.get('jwt')
    const roles = Cookies.get('roles');
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    return (
        <div className={'Dashboard_wrapper'}>
            <ScrollBlock lang={lang}  clickInitiative={clickInitiative} setClickInitiative={setClickInitiative} to10Data={to10Data}/>
            <div className={'Dashboard_wrap'}>
                <div className={'Dashboard_left'}>
                    <div className={'Dashboard_banner'} style={{ backgroundImage:`url(${BgBanner})`}} >
                        <span >{lanGs['Твоя инициатива важна для города!'][lang]}</span>
                        <img src={People} alt="icon people"/>
                    </div>
                    <span className={'Dashboard_banner_bottom_text'}>{lanGs['Здесь вы можете'][lang]}</span>
                    <div className={'Dashboard_cards_wrap'}>
                        <div className={'Dashboard_cards_btn_wrap'}>

                           <div className={'Dashboard_cards_btn map_btn'} onClick={() =>    navigate('/initiative-map')}>
                               <EnvironmentOutlined />
                               <span>{lanGs['Посмотреть на карте'][lang]}</span>
                                </div>
                            <span className={`Dashboard_cards_btn ${!tabBtn ? 'active': ''}`} onClick={() => {
                                setTabBtn(false)
                            }}>
                               {lanGs['Инициативы на голосовании'][lang]}
                            </span>
                            <span className={`Dashboard_cards_btn ${tabBtn  ? 'active' : ''}`} onClick={() => setTabBtn(true)}>
                                {lanGs['Архив инициатив'][lang]}
                            </span>

                        </div>
                       <div className={'Dashboard_cards_cards'}>
                           {dataCat && <div className={'Dashboard_card'} onClick={() => {
                               setCatCardClick(null)
                               navigate('/initiative')
                               Cookies.set('catC', null)

                           }}>
                               <span className={'Dashboard_card_title'}>{lanGs['Все инициативы'][lang]}</span>
                               <div className={'Dashboard_card_item'}>
                                   <span>{dataCat?.total}</span>
                                   <img src={CatAll} alt=""/>
                               </div>
                           </div>}
                           {dataCat?.categories?.map((i, index) => {
                              if(i.id !==18)
                               return <div className={'Dashboard_card'}  key={i.id + 'index' + index} onClick={() => {
                                /*   setCatCardClick(i.id)*/

                                   navigate('/initiative')
                                   Cookies.set('catC', i.id)

                               }}>
                                   <span className={'Dashboard_card_title'}>{i.name[lang]}</span>
                                   <div className={'Dashboard_card_item'}>
                                       <span>{i.amount}</span>
                                       <img src={i.icon} alt=""/>
                                   </div>
                               </div>
                           })}
                       </div>
                    </div>
                </div>
                <div className={'Dashboard_right'}>
                    <div className={'Dashboard_nav_top'}>
                        <div className={'Dashboard_nav_title'}>
                            <span>{totalInfo?.amount ? totalInfo?.amount : ''}</span>
                            <span>на {moment().format("DD.MM.YYYY")}</span>
                            <span>{lanGs['Всего поданных инициатив'][lang]} </span>
                        </div>
                        <div className={'Dashboard_nav_btn_wrap'}>
                            { token && roles?.includes('ROLE_MODERATOR')  && <span className={`Dashboard_nav_btn`} onClick={() => {
                                navigate('/initiative-moder')
                                setResetTable(true)
                            }}>
                                {lanGs['Модерация'][lang]}
                            </span>}
                            {roles === 'ROLE_SOCIAL_USER'?
                                <Popover
                                    overlayClassName={'Dashboard_nav_reg'}
                                    className={'Dashboard_nav_reg_wrap'}
                                    content={
                                    <div className={'Dashboard_nav_reg_item'}>
                                        <Button onClick={hide}>Отмена</Button>
                                        <Button onClick={() => {
                                            navigate('/login');
                                            Cookies.remove('jwt');
                                            Cookies.remove('token');
                                            Cookies.remove('name');
                                            Cookies.remove('email');
                                            Cookies.remove('roles');
                                        }
                                        }>Регистрация</Button>

                                    </div>}
                                    title={lanGs['noSocial'][lang]}
                                    trigger="click"
                                    open={open}
                                    onOpenChange={handleOpenChange}>
                                     <span className={`Dashboard_nav_btn `} >{lanGs['Опубликовать инициативу'][lang]}</span>
                                </Popover>:
                                <span className={`Dashboard_nav_btn `} onClick={() => {token ?  navigate('/add-initiatives'): navigate('/login')}}>
                               {lanGs['Опубликовать инициативу'][lang]}
                            </span>
                            }

                            <span className={`Dashboard_nav_btn `} onClick={() => { navigate('/workMap') }}>
                               {lanGs['Ремонтные работы по г.Алматы'][lang]}

                            </span>
                            <span className={`Dashboard_nav_btn`} onClick={() => {navigate('/rules')}}>
                                {lanGs['Правила'][lang]}
                            </span>
                            <a href={'https://almatydc.kz/press/news'} target={'_blank'} className={`Dashboard_nav_btn link_item`} onClick={() => {}}>
                               {lanGs['Новости'][lang]}
                            </a>
                           {/* <a className={`Dashboard_nav_btn`}
                               href={'https://UserRO:123qweASD@power.smartalmaty.kz/powerbi/?id=1b78b3aa-d241-417f-b55c-35b00b4276b4&formatLocale=ru-RU'}
                               target={'_blank'}
                               onClick={() => {sendMetrik('ClickOnAnalytics',)}}
                            >
                               {lanGs['Аналитика'][lang]}
                            </a>*/}
                            <span className={`Dashboard_nav_btn`} onClick={() => {navigate('/about')}}>
                                {lanGs['О проекте'][lang]}
                            </span>
                        </div>
                    </div>
                    <div className={'Dashboard_nav_bot'}>
                        <span>{lanGs['Бюджет народного участия'][lang]}</span>
                        <span>budget.open-almaty.kz</span>
                        <a href={'https://budget.open-almaty.kz/'}
                           target={'_blank'}
                           onClick={() => {sendMetrik('ClickOnBNY', )}}
                        >
                            {lanGs['Подать предложение'][lang]}
                        </a>
                        {/*<span>до 26 июня</span>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
