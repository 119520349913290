export const lanGs ={

    'Пожалуйста, используйте только кириллицу для ввода.': {
        nameRu: "Пожалуйста, используйте только кириллицу для ввода.",
        nameKz: "Теру үшін тек кириллицаны пайдаланыңыз.",
    },
    'Данные профиля успешно изменены': {
        nameRu: "Данные профиля успешно изменены",
        nameKz: "Профиль деректері сәтті өзгертілді",
    },
    'Что то пошло не так, попробуйте позднее': {
        nameRu: "Что то пошло не так, попробуйте позднее",
        nameKz: "Бірдеңе дұрыс болмады, кейінірек көріңіз",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Введите телефонный номер в формате +7(777)777 77 77': {
        nameRu: "Введите телефонный номер в формате +7(777)777 77 77",
        nameKz: "Телефон нөмірін +7(777)777 77 77 форматында енгізіңіз",
    },
    'Сбросить': {
        nameRu: "Сбросить",
        nameKz: "Қалпына келтіру",
    },
    'Фамилия': {
        nameRu: "Фамилия",
        nameKz: "Тегі",
    },
    'Имя': {
        nameRu: "Имя",
        nameKz: "Аты",
    },
    'Обязательное поле': {
        nameRu: "Обязательное поле",
        nameKz: "Міндетті өріс",
    },
    'Пол': {
        nameRu: "Пол",
        nameKz: "Жындысы",
    },
    'Мужской': {
        nameRu: "Мужской",
        nameKz: "Еркек",
    },
    'Женский': {
        nameRu: "Женский",
        nameKz: "Әйел",
    },
    'Дата рождения': {
        nameRu: "Дата рождения",
        nameKz: "Туған күні",
    },
    'ИИН/БИН': {
        nameRu: "ИИН/БИН",
        nameKz: "ЖСН/БСН",
    },
    'Телефон': {
        nameRu: "Телефон",
        nameKz: "Телефон",
    },
    'Компания': {
        nameRu: "Компания",
        nameKz: "Компания",
    },
    'Руководитель': {
        nameRu: "Руководитель",
        nameKz: "Басшы",
    },
    'Адрес': {
        nameRu: "Адрес",
        nameKz: "Мекен-жайы",
    },
    'Район': {
        nameRu: "Район",
        nameKz: "Аудан",
    },
    'Выберите район': {
        nameRu: "Выберите район",
        nameKz: "Аймақты таңдаңыз",
    },
    'МКР': {
        nameRu: "МКР",
        nameKz: "ША",
    },
    'Введите текст для поиска': {
        nameRu: "Введите текст для поиска",
        nameKz: "Іздеу үшін мәтінді енгізіңіз",
    },
    'Пожалуйста, используйте только кириллические символы и цифры.': {
        nameRu: "Пожалуйста, используйте только кириллические символы и цифры.",
        nameKz: "Тек кирилл таңбалары мен сандарын қолданыңыз.",
    },
    'Улица': {
        nameRu: "Улица",
        nameKz: "Көше",
    },
    'Дом': {
        nameRu: "Дом",
        nameKz: "Үй",
    },
    'Квартира': {
        nameRu: "Квартира",
        nameKz: "Пәтер",
    },
    'Задать вопрос': {
        nameRu: "Задать вопрос",
        nameKz: "Сұрақ қою",
    },
    'Отправить': {
        nameRu: "Отправить",
        nameKz: "Жіберу",
    },
    'Назад': {
        nameRu: "Назад",
        nameKz: "Артқа",
    },
    'Подтверждение изменений': {
        nameRu: "Подтверждение изменений",
        nameKz: "Өзгерістерді растау",
    },
    'Вы действительно хотите внести изменения в профиль?': {
        nameRu: "Вы действительно хотите внести изменения в профиль?",
        nameKz: "Сіз шынымен профильге өзгертулер енгізгіңіз келеді?",
    },






}
