import React, {useRef, useState} from 'react';
import Slider from "react-slick";
import {  Tooltip } from 'antd';
import './ScrollBlock.css'
import {declOfNum, numIcon} from '../.../../../utils/helper'
import CardInitiativeBlock from "../element/CardInitiativeBlock";
import {lanGs} from "../../utils/lang/langScrollBlock";


const ScrollBlock = ({lang, clickInitiative, setClickInitiative, to10Data, dataCardPas, setDataCardPas}) => {

    const settings = {
        dots: false,
        autoplay: to10Data?.length > 1, // Автопрокрутка только при наличии более одного элемента
        autoplaySpeed: 2500,
        infinite: to10Data?.length > 1, // Бесконечная прокрутка только если более одного элемента
        speed: 500,
        centerMode: to10Data?.length > 1, // Центрация только при наличии более одного элемента
        cssEase: 'linear',
        slidesToShow: 1, // Показ одного элемента
        variableWidth: to10Data?.length > 1, // Ширина адаптируется только если более одного элемента
    };
    return (
        <div className="ScrollBlock_wrap">
        <span className="ScrollBlock_title">
            {lanGs['ТОП-10 инициатив с наибольшим числом голосов'][lang]}
        </span>
            {to10Data?.length > 1 ? (
                <Slider {...settings}>
                    {to10Data.map((i, index) => (
                        <div className="carousel-item" key={i.id + i.name}>
                            <div
                                className="carousel-item_card"
                                onClick={() => setClickInitiative(i.id)}
                            >
                                <img src={numIcon[index]} alt="" className="carousel-img-back" />
                                <img src={i.icon} alt="" className="carousel-item_img" />
                                <Tooltip placement="topLeft" title={i.name}>
                                    <span className="carousel-item_text">{i.name}</span>
                                </Tooltip>
                                <div className="carousel-item_value">
                                    <span>{i.voteAmount}</span>
                                    <span>
                                    {declOfNum(i.voteAmount, [
                                        `${lanGs['голос'][lang]}`,
                                        `${lanGs['голоса'][lang]}`,
                                        `${lanGs['голосов'][lang]}`,
                                    ])}
                                </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="carousel-static">
                    {to10Data?.map((i, index) => (
                        <div className="carousel-item" key={i.id + i.name}>
                            <div
                                className="carousel-item_card"
                                onClick={() => setClickInitiative(i.id)}
                            >
                                <img src={numIcon[index]} alt="" className="carousel-img-back" />
                                <img src={i.icon} alt="" className="carousel-item_img" />
                                <Tooltip placement="topLeft" title={i.name}>
                                    <span className="carousel-item_text">{i.name}</span>
                                </Tooltip>
                                <div className="carousel-item_value">
                                    <span>{i.voteAmount}</span>
                                    <span>
                                    {declOfNum(i.voteAmount, [
                                        `${lanGs['голос'][lang]}`,
                                        `${lanGs['голоса'][lang]}`,
                                        `${lanGs['голосов'][lang]}`,
                                    ])}
                                </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <CardInitiativeBlock
                lang={lang}
                clickInitiative={clickInitiative}
                setClickInitiative={setClickInitiative}
                dataCardPas={dataCardPas}
                setDataCardPas={setDataCardPas}
            />
        </div>
    );

};

export default ScrollBlock;
