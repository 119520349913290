import React, {useEffect, useState} from 'react';
import './TableBlockModer.css'
import BackBlock from "../common/element/BackBlock";
import {Tabs} from "antd";
import TableInitiative from '../common/element/TableInitiative'
import {lanGs} from '../utils/lang/langTableBlock'

const TableBlockModer = ({lang, setClickInitiative, setCatCardClick, data, setResetTable, setTabsKey, setData, setDistChoice }) => {
    useEffect(() => {setTabsKey(5)}, []);
    const onChange = (key) => {setTabsKey(key)};

    return (
        <div className={'TableBlockModer_wrap'}>
            <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice} setTabsKey={setTabsKey}/>
            <div className={'TableBlockModer_tab'}>
                    <Tabs  type="card" onChange={onChange} items={[
                         {
                            label: lanGs['На проверке'][lang],
                            key: '5',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 5)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['На доработке'][lang],
                            key: '2',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 2)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['Отказ'][lang],
                            key: '3',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 3)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['На голосовании'][lang],
                            key: '1',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 1)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['В архиве'][lang],
                            key: '4',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 4)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['Одобрен'][lang],
                            key: '6',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 6)} setClickInitiative={setClickInitiative} setResetTable={setResetTable} moder={true}/>,
                        },
                        ]}/>
            </div>
        </div>
    );
};

export default TableBlockModer;
