import React, {useEffect, useState} from 'react';
import './TableBlock.css'
import BackBlock from "../common/element/BackBlock";
import {Select, Tabs} from "antd";
import TableInitiative from '../common/element/TableInitiative'
import Cookies from "js-cookie";
import axios from "axios";
import {lanGs} from '../utils/lang/langTableBlock'

const TableBlock = ({lang, setClickInitiative, setCatCardClick, data, isModer=false, setData, setDistChoice, tabBtn , setTabsKey, setResetTable, archiveYears, setIsYear}) => {

    useEffect(() => {
        setTabsKey(0)
    }, []);

    const onChange = (key) => {

        setTabsKey(key)
    };
    const handleChange = (value) => {
        setIsYear(value)
    };
    return (
        <div className={'TableBlock_wrap'}>
            <BackBlock lang={lang}  setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
            <div className={'TableBlock_tab'}>
                {tabBtn && <Select style={{width: '150px',}}
                                   className={'TableBlock_years'}
                                   options={archiveYears?.map(i => {
                                       return {value: i, label: i}
                                   })}
                                   defaultValue={archiveYears[0]}
                                   size="middle"
                                   onChange={handleChange}
                                   placeholder={lanGs['Выбрать год'][lang]}
                />}
                {!tabBtn ?
                    <TableInitiative
                        lang={lang}
                        data={!tabBtn ? data?.filter(i => i.status.id === 1) : data?.filter(i => i.status.id === 4)}
                        setClickInitiative={setClickInitiative} moder={false}/>:
                    <Tabs type="card" onChange={onChange} items={[
                        {
                            label: lanGs['В архиве'][lang],
                            key: '4',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 4 )} setClickInitiative={setClickInitiative}
                                                       setResetTable={setResetTable} moder={true}/>,
                        },
                        {
                            label: lanGs['Одобрен'][lang],
                            key: '6',
                            children: <TableInitiative lang={lang} data={data?.filter(i => i.status.id === 6)} setClickInitiative={setClickInitiative}
                                                       setResetTable={setResetTable} moder={true}/>,
                        },
                    ]}/>
                }

            </div>
        </div>
    );
};

export default TableBlock;
